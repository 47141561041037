import { useEffect, useContext } from 'react'

// APIS
import { axiosPrivateLacak } from 'apis/axiosEntLacak'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// HOOKS
import useRefreshTokenLacak from 'hooks/useRefreshTokenLacak'

const useAxiosPrivateLacak = () => {
  const refreshTokenLacak = useRefreshTokenLacak()

  const { auth } = useContext(AllPagesContext)

  useEffect(() => {
    const requestIntercept = axiosPrivateLacak?.interceptors?.request?.use(
      config => {
        if (!config?.params['hash']) config.params['hash'] = auth?.hashTokenLacak

        return config
      },
      (error) => Promise.reject(error)
    )

    const responseIntercept = axiosPrivateLacak?.interceptors?.response?.use(
      response => response,
      async (error) => {
        const previousRequest = error?.config

        if ((error?.response?.status === 400) && !previousRequest?.sent) {
          previousRequest.sent = true
          const newHashTokenLacak = await refreshTokenLacak()
          previousRequest.params['hash'] = `Bearer ${newHashTokenLacak}`

          return axiosPrivateLacak(previousRequest)
        }
        
        return Promise.reject(error)
      }
    )

    return () => {
      axiosPrivateLacak?.interceptors?.request?.eject(requestIntercept)
      axiosPrivateLacak?.interceptors?.request?.eject(responseIntercept)
    }
  }, [auth.hashTokenLacak])

  return axiosPrivateLacak
}

export default useAxiosPrivateLacak