import React, { createContext, useState, useRef } from 'react'

// CONSTANTS
import { 
  initialMoreList, 
  initialSettingList,
} from 'constants/valuesPageTracking'

// MUI DATA GRID
import { useGridApiRef } from '@mui/x-data-grid-pro'

// UTILITIES
import { readReferenceLocationFromLocalStorage } from 'utilities/localStorage'

const PageTrackingContext = createContext()

const PageTrackingContextProvider = (props) => {

  // BOOLEAN
  const [ isObjectsPanelShown, setIsObjectsPanelShown ] = useState(true)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isOpenPanelPieChart, setIsOpenPanelPieChart ] = useState(false)
  const [ isPanelDetailObjectShown, setIsPanelDetailObjectShown ] = useState(false)
  const [ isAddingReferenceMarker, setIsAddingReferenceMarker ] = useState(false)
  const [ isPanelDetailExpanded, setIsPanelDetailExpanded ] = useState(true)
  const [ isPanelGeofencesShown, setIsPanelGeofencesShown ] = useState(false)
  const [ hasGeofenceListFinishedLoading, setHasGeofenceListFinishedLoading ] = useState(true)
  const [ isPanelCreateOrEditGeofenceShown, setIsPanelCreateOrEditGeofenceShown ] = useState(false)

  // LIST
  const [ objectList, setObjectList ] = useState([])
  const [ locationList, setLocationList ] = useState([])
  const [ groupList, setGroupList ] = useState([])
  const [ objectSelectionModel, setObjectSelectionModel ] = useState([])
  const [ geofenceSelectionModel, setGeofenceSelectionModel ] = useState([])
  const [ moreList, setMoreList ] = useState(initialMoreList)
  const [ settingList, setSettingList ] = useState(initialSettingList)
  const [ referenceLocation, setReferenceLocation ] = useState(readReferenceLocationFromLocalStorage)
  const [ selectedGeofenceList, setSelectedGeofenceList ] = useState([])

  // OBJECT
  const pageRef = useRef(null)
  const panelObjectsDataGridApiRef = useGridApiRef()
  const panelGeofencesDataGridApiRef = useGridApiRef()
  
  return (
    <PageTrackingContext.Provider
      value={{
        // BOOLEAN
        isObjectsPanelShown, setIsObjectsPanelShown,
        isLoading, setIsLoading,
        isOpenPanelPieChart, setIsOpenPanelPieChart,
        isPanelDetailObjectShown, setIsPanelDetailObjectShown,
        isAddingReferenceMarker, setIsAddingReferenceMarker,
        isPanelDetailExpanded, setIsPanelDetailExpanded,
        isPanelGeofencesShown, setIsPanelGeofencesShown,
        hasGeofenceListFinishedLoading, setHasGeofenceListFinishedLoading,
        isPanelCreateOrEditGeofenceShown, setIsPanelCreateOrEditGeofenceShown,
        // LIST
        objectList, setObjectList,
        locationList, setLocationList,
        groupList, setGroupList,
        objectSelectionModel, setObjectSelectionModel,
        geofenceSelectionModel, setGeofenceSelectionModel,
        moreList, setMoreList,
        settingList, setSettingList,
        referenceLocation, setReferenceLocation,
        selectedGeofenceList, setSelectedGeofenceList,
        // OBJECT
        pageRef,
        panelObjectsDataGridApiRef,
        panelGeofencesDataGridApiRef,
      }}
    >
      {props.children}
    </PageTrackingContext.Provider>
  )
}

export { PageTrackingContextProvider, PageTrackingContext }