// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 320,
  },
  textTitle: {
    marginBottom: 8,
    fontWeight: 500,
  },
  textCaption: {
    color: theme.palette.text.secondary,
    marginBottom: 60,
  },
  formItem: {
    display: 'flex',
    alignItems: 'center',
    height: 64,
    width: '100%',
  },
  formItemIcon: {
    color: theme.palette.grey[500],
    marginRight: 9,
  },
  input: {
    width: '100%',
  },
  inputHelper: {
    height: 15,
  },
  iconPassword: {
    cursor: 'pointer',
    color: theme.palette.grey[600],
  },
  link: {
    marginTop: 12,
  },
  button: {
    height: 42,
    borderRadius: 0,
    borderTopLeftRadius: 24,
    borderBottomRightRadius: 24,
    marginTop: 32,
  },
}))

export default useStyles