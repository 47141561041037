import { useContext } from 'react'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// SERVICES
import { postAuthenticateLacakUser } from 'services/entLacak/auth'

// UTILS
import { signOutUser } from 'utilities/authentication'
import { setUserProfileToLocalStorage } from 'utilities/localStorage'

const useRefreshTokenLacak = () => {
  const { 
    auth, setAuth, 
    setSnackbarObject,
  } = useContext(AllPagesContext)

  const refreshTokenLacak = async () => {
    const resultRefreshTokenLacak = await postAuthenticateLacakUser()

    const error40XRegex = /^40[0-9]$/

    if (resultRefreshTokenLacak.status === 200) {
      setUserProfileToLocalStorage({
        ...auth,
        hashTokenLacak: resultRefreshTokenLacak?.data?.hash,
      })

      setAuth(current => {
        return {
          ...current,
          hashTokenLacak: resultRefreshTokenLacak?.data?.hash,
        }
      })

      return resultRefreshTokenLacak?.data?.hash
    }
    else if (error40XRegex.test(resultRefreshTokenLacak.status)) {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Sorry, your session has expired',
      })
      
      signOutUser(setAuth)
    }
  }

  return refreshTokenLacak
}

export default useRefreshTokenLacak