// CONSTANTS
import { values } from 'constants/values'

// MUIS
import MuiDrawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'

const openedMixin = (theme) => ({
  width: values.drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowY: 'unset',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowY: 'unset',
  width: values.drawerWidthOnMinimized,
})

const CustomPermanentDrawer = styled(MuiDrawer, { 
  shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: values.drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
)

export default CustomPermanentDrawer