import ReactDOMServer from 'react-dom/server'

// LEAFLET
import L from 'leaflet'

// MUIS
import Box from '@mui/material/Box'

// PIE CHART
import { PieChart } from 'react-minimal-pie-chart'

// THIS IS NOT A REACT COMPONENT SO HOOKS WILL NOT WORK HERE
const MarkerClusterIcon = (cluster, mapContext, dataGroup) => {
  const lineWidth = 65
  const limitGetChildCluster = 20000

  // SET SIZE PIE CHART BY ZOOM
  const getSizePieChart = () => {
    if(mapContext?.getZoom() <= 10) return 30
    else if(mapContext?.getZoom() <= 20) return 25
  }

  // SET DATA PIE CHART
  const getDataPieChart = () => {
    // GET CLUSTER CHILD
    const clusterChild = cluster.superclusterRef.current.getLeaves(cluster.detail.id, limitGetChildCluster)

    // DATA GROUP
    let getValueGroups = dataGroup.map(itemGroup => {
      // FILTER CHILD CLUSTER BY ID GROUP
      const filteredChildCluster = clusterChild
        .filter(itemChild => itemChild.markerData.group.id === itemGroup.id)

      return {
        title: itemGroup.groupName.replace(/-/g, ' '),
        color: `#${itemGroup?.groupColor?.replace('#', '')}`,
        value: filteredChildCluster.length
      }
    })

    // DONT SHOW ITEM HAVE 0 VALUE ON PIE CHART
    return getValueGroups.filter(item => item.value > 0)
  }

  return L.divIcon({
    className: 'marker-pie-chart',
    html: ReactDOMServer.renderToString(
      <Box
        // SX PROP IS NOT WORKING HERE
        style={{
          width: (getSizePieChart() + 8) * 2,
          height: (getSizePieChart() + 8) * 2,
          opacity: 0.8,
          filter : 'drop-shadow(0px 0px 3px rgba(0,0,0,0.4))',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <PieChart
          data={getDataPieChart()}
          radius={getSizePieChart()}
          center={[(getSizePieChart() + 8), (getSizePieChart() + 8)]}
          viewBoxSize={[(getSizePieChart() + 8) * 2, (getSizePieChart() + 8) * 2]}
          lineWidth={lineWidth}
          label={({dataEntry}) => dataEntry.value}
          labelPosition={100 - lineWidth / 2}
          labelStyle={{
            fill: '#FFFFFF',
            fontSize: '12px !important',
            fontWeight: 500,
            opacity: 1,
            pointerEvents: 'none'
          }}
        />
      </Box>
    )
  })
}

export default MarkerClusterIcon