// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    height: 40,
    width: 40,
    left: 20,
    minWidth: 'unset',
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    boxShadow: theme.shadows[4],
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}))

export default useStyles