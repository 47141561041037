// MUIS
import Avatar from '@mui/material/Avatar'
import { alpha } from '@mui/material/styles'

// STYLES
import useStyles from './panelDetailObjectUseStyles'

const CardIcon = (props) => {
  const { 
    Icon, 
    color, 
  } = props
  
  const classes = useStyles()

  return (
    <Avatar 
      className={classes.contentCardHeaderAvatar}
      sx={{ 
        '&.MuiAvatar-root': {
          backgroundColor: alpha(color, 0.12),
        },
      }}
    >
      <Icon 
        className={classes.contentCardHeaderIcon}
        sx={{ color: color }}
      />
    </Avatar>
  )
}

export default CardIcon