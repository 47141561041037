export const values = {
  // ALL PAGES
  fontFamily: [ 'DM Sans', 'sans-serif' ].join(','),

  // DRAWER
  drawerWidth: '256px',
  drawerWidthOnMinimized: '72px',

  // GENERAL
  zoomValue: 0.85,

  // ENV
  apiRequestInterval: process.env.REACT_APP_API_REQUEST_INTERVAL,

  // SNACKBAR
  initialSnackbarObject: {
    open: false,
    severity: 'success',
    title: '',
    message: '',
  },
}