import React from 'react'

// CONSTANTS
import { values } from 'constants/values'

// MUIS
import MuiGlobalStyles from '@mui/material/GlobalStyles'
import { alpha } from '@mui/material/styles'

const GlobalStyles = () => {
  return (
    <MuiGlobalStyles
      styles={(theme) => ({
        // ALL ELEMENTS
        '*, *::before, *::after': {
          boxSizing: 'border-box',
          fontFamily: values.fontFamily,
          shapeRendering: 'geometricPrecision',
          textRendering: 'geometricPrecision',
          imageRendering: 'optimizeQuality',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
        },

        // GENERAL
        '.colorTextPrimary': {
          color: `${theme.palette.text.primary} !important`,
        },
        '.colorTextSecondary': {
          color: `${theme.palette.text.secondary} !important`,
        },
        '.colorWhite': {
          color: `${theme.palette.common.white} !important`,
        },
        '.cursorPointer': {
          cursor: 'pointer !important',
        },
        '.fontWeight500': {
          fontWeight: '500 !important',
        },
        '.fullWidth': {
          width: '100% !important',
        },
        '.padding0': {
          padding: '0px !important',
        },
        '.marginTopAuto': {
          marginTop: 'auto',
        },

        // SCROLLBAR
        '&::-webkit-scrollbar': {
          width: 5,
          height: 5,
          backgroundColor: alpha('#000000', 0.16),
        },
        '&::-webkit-scrollbar-thumb': {
          width: 5,
          height: 5,
          backgroundColor: alpha('#000000', 0.2),
        },

        // ZOOM
        '@media only screen and (max-height: 820px)': {
          'body': {
            zoom: values.zoomValue,
          },
          '.zoom': {
            zoom: values.zoomValue,
          },
          '.no-zoom': {
            zoom: 1 / values.zoomValue,
          },

          // NEUTRALIZE ZOOM EFFECTS
          '.neutralize-zoom-menu': {
            '& .MuiPaper-root': {
              zoom: 1 / values.zoomValue,
            },
            '& .MuiList-root': {
              zoom: values.zoomValue,
            },
          },
          '.neutralize-zoom-tooltip': {
            zoom: 1 / values.zoomValue,
            '& .MuiIconButton-root .MuiSvgIcon-root': {
              zoom: values.zoomValue,
            },
          },
        },
      })}
    />
  )
}

export default GlobalStyles