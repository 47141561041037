import { useContext } from 'react'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// MUIS
import Button from '@mui/material/Button'

// MUI ICONS
import IconStar from '@mui/icons-material/Star'

// STYLES
import useStyles from './panelReferenceMarkerUseStyles'

// UTILITIES
import { removeReferenceLocationFromLocalStorage } from 'utilities/localStorage'

const PanelReferenceMarker = () => {
  const classes = useStyles()

  const { 
    isPanelDetailObjectShown, 
    isPanelDetailExpanded,
    setIsAddingReferenceMarker,
    referenceLocation, setReferenceLocation,
  } = useContext(PageTrackingContext)

  const handleButtonClick = () => {
    // WILL ADD REFERENCE MARKER TO THE MAP
    if (referenceLocation.length === 0) setIsAddingReferenceMarker(true)
    // REMOVE THE EXISTING REFERENCE MARKER FROM THE MAP
    else if (referenceLocation.length === 2) {
      setReferenceLocation([])
      removeReferenceLocationFromLocalStorage()
      setIsAddingReferenceMarker(false)
    }
  }

  const getBottomPosition = () => {
    if (isPanelDetailObjectShown) {
      if (isPanelDetailExpanded) return 280
      else return 60
    }
    else return 20
  }

  return (
    <Button
      className={classes.container}
      onClick={handleButtonClick}
      sx={{ bottom: getBottomPosition() }}
    >
      <IconStar sx={(theme) => ({
        color: referenceLocation.length === 2 
          ? theme.palette.primary.main 
          : theme.palette.action.active,
      })}/>
    </Button>
  )
}

export default PanelReferenceMarker