import { createContext, useState } from 'react'

const LayoutPrivateContext = createContext()

const LayoutPrivateContextProvider = (props) => {
  // DRAWER
  const [ isDrawerExpanded, setIsDrawerExpanded ] = useState(true)

  return (
    <LayoutPrivateContext.Provider
      value={{
        isDrawerExpanded, setIsDrawerExpanded,
      }}
    >
      {props.children}
    </LayoutPrivateContext.Provider>
  )
}

export { LayoutPrivateContextProvider, LayoutPrivateContext }