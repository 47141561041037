// MUIS
import Box from '@mui/material/Box'

// MUI ICONS
import IconStar from '@mui/icons-material/Star'

const MarkerReference = (props) => {
  const { classes } = props

  return (
    <Box className={`${classes.baseCircle} ${classes.root}`}>
      {/* BLINKING CIRLCE */}
      <Box className={`${classes.baseCircle} ${classes.blinkingCircle}`}/>

      {/* ICON CONTAINER */}
      <Box className={`${classes.baseCircle} ${classes.iconContainer}`}>
        {/* ICON */}
        <IconStar fontSize='small'/>
      </Box>
    </Box>
  )
}

export default MarkerReference