// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 372,
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    position: 'relative',
    boxShadow: theme.shadows[4],
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 2,
    backgroundColor: alpha(theme.palette.background.paper, 0.4),
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 'inherit',
  },
  loading: {
    width: '48px !important',
    height: '48px !important',
  },
  header: {
    height: 56,
    padding: '0px 8px',
  },
  headerIcon: {
    marginRight: 8,
    marginLeft: 2,
  },
  panelSettingItem: {
    height: 'fit-content',
  },
  cellGroup: {
    width: '100%',
    height: '100%',
  },
  cellGroupText: {
    width: '100%',
    fontWeight: 600,
  },
  cellGroupIcon: {
    marginRight: 18,
  },
  cellItemContainer: {
    width: '100%',
    paddingRight: 24,
  },
  cellItemIconStatus: {
    height: 12,
    width: 12,
  },
  cellItemLabel: {
    flex: 1,
    paddingRight: 8,
  },
  cellItemSpeed: {
    width: 60,
    textAlign: 'right',
    marginRight: 10
  },
  moreMenuItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  moreMenuItemIcon: {
    marginLeft: -8,
  },
}))

export default useStyles