const contrastTextWhite = '#FFFFFF'

export const colors = {
  authenticationBackground: '#181818',

  // PRIMARY (RED)
  primary: {
    main: '#BF0D02',
    light: '#EAAEAB',
    dark: '#9F0B02',
    contrastText: contrastTextWhite,
  },
  // SECONDARY (BLUE)
  secondary: {
    main: '#3B5998',
    light: '#BEC8DD',
    dark: '#314A7F',
    contrastText: contrastTextWhite,
  },
  // ERROR (RED)
  error: {
    main: '#F9514E',
    light: '#FA6E6B',
    dark: '#F72B27',
    contrastText: contrastTextWhite,
  },
  // INFO (BLUE)
  info: {
    main: '#2196F3',
    light: '#64B6F7',
    dark: '#0B79D0',
    contrastText: contrastTextWhite,
  },
  // WARNING (ORANGE)
  warning: {
    main: '#FF9141',
    light: '#FFA563',
    dark: '#FF7D1F',
    contrastText: contrastTextWhite,
  },
  // SUCCESS (GREEN)
  success: {
    main: '#4CAF50',
    light: '#5FD998',
    dark: '#29A965',
    contrastText: contrastTextWhite,
  },
  // TEXT (DARK GREY)
  text: {
    primary: '#3B3737',
    secondary: '#8D8D8D',
    disabled: '#A6AAB5',
  },
}