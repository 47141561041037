// APIS
import axios from 'apis/axiosBase'

export const getTrackerList = async (
  inputSignal,
  inputHash,
) => {
  try {
    const response = await axios.get(
      `/tracker/list?hash=${inputHash}`,
      { signal: inputSignal },
    )

    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getTrackerGroupList = async (
  inputSignal,
  inputHash,
) => {
  try {
    const response = await axios.get(
      `/tracker/group/list?hash=${inputHash}`,
      { signal: inputSignal },
    )
    
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postTrackerGetStates = async (
  inputSignal,
  inputBodyParams,
) => {
  try {
    const response = await axios.post(
      '/tracker/get_states',
      inputBodyParams,
      { signal: inputSignal },
    )

    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}