import { useState, useEffect } from 'react'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconPerson from '@mui/icons-material/Person'

// STYLES
import useLayoutStyles from 'styles/layoutAuthentication'

// UTILS
import { isEmailFormatValid } from 'utilities/validation'

const ForgotPassword = () => {
  const layoutClasses = useLayoutStyles()

  const initialForm = {
    email: '',
  }

  const initialErrorForm = {
    email: null,
  }

  const [ form, setForm ] = useState(initialForm)
  const [ errorForm, setErrorForm ] = useState(initialErrorForm)
  const [ isRendered, setIsRendered ] = useState(false)
  const [ isButtonDisabled, setIsButtonDisabled ] = useState(true)

  const handleFormChange = (event) => {
    setForm(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  const handleButtonClick = (inputEvent) => {
    inputEvent.preventDefault()
  }

  useEffect(() => {
    if(!isRendered) {
      setIsRendered(true)
      setErrorForm(initialErrorForm)
    }
  }, [])
  
  useEffect(() => {
    if(isRendered) {
      if(!isEmailFormatValid(form.email)) {
        if(form.email === '') {
          setErrorForm(current => ({
            ...current,
            email: 'This field is required',
          }))
        }
        else {
          setErrorForm(current => ({
            ...current,
            email: 'E-mail must be valid',
          }))
        }
      }
      else {
        setErrorForm(current => ({
          ...current,
          email: null,
        }))
      }
    }
  }, [form.email])

  useEffect(() => {
    if(isRendered) {
      if(form.email === '') setIsButtonDisabled(true)
      else if(Boolean(errorForm.email)) setIsButtonDisabled(true)
      else setIsButtonDisabled(false)
    }
  }, [
    form.email,
    errorForm.email,
  ])

  return (
    <form 
      className={layoutClasses.root} 
      onSubmit={handleButtonClick}
    >
      {/* TITLE */}
      <Typography 
        variant='h4'
        className={layoutClasses.textTitle}
      >
        Forgot password?
      </Typography>

      {/* CAPTION */}
      <Typography
        variant='subtitle1'
        className={layoutClasses.textCaption}
      >
        Please enter your registered email,
        <br/>
        we’ll send you reset instructions.
      </Typography>

      {/* EMAIL INPUT */}
      <Box className={layoutClasses.formItem}>
        {/* ICON */}
        <IconPerson className={layoutClasses.formItemIcon}/>

        {/* INPUT */}
        <FormControl 
          variant='standard'
          className={layoutClasses.input}
          error={Boolean(errorForm.email)}
        >
          <InputLabel>
            Email Address
          </InputLabel>

          <Input 
            autoFocus
            name='email'
            value={form.email}
            onChange={handleFormChange}
          />

          {/* ERROR TEXT */}
          <FormHelperText className={layoutClasses.inputHelper}>
            {errorForm.email}
          </FormHelperText>
        </FormControl>
      </Box>

      {/* SEND BUTTON */}
      <Button
        type='submit' 
        disabled={isButtonDisabled}
        variant='contained'
        className={layoutClasses.button}
        disableElevation
        fullWidth
      >
        Send Instruction
      </Button>
    </form>
  )
}

export default ForgotPassword