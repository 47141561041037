// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 372,
    position: 'absolute',
    top: 80,
    left: 20,
    zIndex: 2,
    boxShadow: theme.shadows[4],
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
  },
}))

export default useStyles