import { useState, useEffect, useContext, useRef } from 'react'

// COMPONENTS
import Clustering from './Clustering'
import GeofenceList from './GeofenceList/GeofenceList'
import MapCamera from 'components/MapCamera/MapCamera'
import MapClusterMenu from 'components/MapClusterMenu/MapClusterMenu'
import MapInvalidateSize from 'components/MapInvalidateSize/MapInvalidateSize'
import MapLocationReference from './MapLocationReference/MapLocationReference'
import MarkerIcon from 'components/MapMarkers/MarkerIcon'
import PanelCreateOrEditGeofence from './PanelCreateOrEditGeofence/PanelCreateOrEditGeofence'
import PanelDetailObject from './PanelDetailObject/PanelDetailObject'
import PanelGeofences from './PanelGeofences/PanelGeofences'
import PanelMenu from './PanelMenu/PanelMenu'
import PanelObjects from './PanelObject/PanelObject'
import PanelReferenceMarker from './PanelReferenceMarker/PanelReferenceMarker'
import PanelToggle from './PanelToogle/PanelToogle'

// CONSTANTS
import { dataGridRowHeight } from 'constants/valuesPageTracking'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { LayoutPrivateContext } from 'contexts/LayoutPrivateContext'
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// LEAFLET
import L from 'leaflet'
import { 
  MapContainer, 
  TileLayer, 
} from 'react-leaflet'
import 'l.movemarker'

// MUIS
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

// MUI DATA GRID
import { gridVisibleSortedRowIdsSelector } from '@mui/x-data-grid-pro'

// SERVICES
import { 
  getTrackerGroupList,
  getTrackerList, 
  postTrackerGetStates,
} from 'services/base/tracker'

// STYLES
import useStyles from './trackingUseStyles'
import 'leaflet/dist/leaflet.css'

// UTILITIES
import { signOutUser } from 'utilities/authentication'
import { getDistanceFromTwoLocations } from 'utilities/number'
import { isHashTokenExpired } from 'utilities/validation'

const Tracking = () => {
  const classes = useStyles()

  const theme = useTheme()

  const { 
    auth, setAuth, 
    setSnackbarObject,
  } = useContext(AllPagesContext)

  const { isDrawerExpanded } = useContext(LayoutPrivateContext)

  const {
    groupList, setGroupList,
    isObjectsPanelShown, setIsObjectsPanelShown,
    isOpenPanelPieChart, setIsOpenPanelPieChart,
    setIsLoading,
    isPanelDetailObjectShown, setIsPanelDetailObjectShown,
    isPanelDetailExpanded, setIsPanelDetailExpanded,
    isAddingReferenceMarker,
    objectList, setObjectList, 
    locationList,
    objectSelectionModel, setObjectSelectionModel,
    settingList,
    moreList,
    referenceLocation,
    pageRef,
    panelObjectsDataGridApiRef,
  } = useContext(PageTrackingContext)

  const initialMapCenter = [-0.7893, 113.9213]
  const initialMapZoom = 5
  const minimalMapZoom = 2
  const maximalMapZoom = 20

  const isAnimatePolylinesRef = useRef(true)

  const [ map, setMap ] = useState()
  const [ trackerList, setTrackerList ] = useState([])
  const [ objectListInstance, setObjectListInstance ] = useState([])
  const [ webSocketStateList, setWebSocketStateList ] = useState([])
  const [isDataAdded, setIsDataAdded] = useState(false)
  const [ isWebsocketRun, setIsWebsocketRun ] = useState(false)

  const integrateObjectData = (
    inputTrackerList,
    inputStateList,
    inputGroupList,
  ) => {
    let output = inputTrackerList.map(trackerItem => {
      let stateObject = inputStateList.find(stateItem => {
        if (stateItem.source_id === trackerItem.source.id) return stateItem
      })

      const latestPositions = []

      if (stateObject) stateObject.distance_to_reference = null
      if (stateObject?.gps?.location?.lat && stateObject?.gps?.location?.lng) {
        if (referenceLocation.length > 0) {
          stateObject.distance_to_reference = getDistanceFromTwoLocations(
            referenceLocation,
            [ stateObject.gps.location.lat, stateObject.gps.location.lng ],
          )
        }

        latestPositions.push([ stateObject?.gps?.location?.lat, stateObject?.gps?.location?.lng ])
      }

      let groupObject = inputGroupList.find((groupItem) => groupItem.id === trackerItem.group_id)

      if (!groupObject) groupObject = {
        id: 0,
        color: theme.palette.primary.main,
        title: 'Main Group',
      }

      return {
        ...trackerItem,
        state: stateObject ? { ...stateObject} : {},
        latestPositions,
        group: { ...groupObject },
      }
    })

    output = output.filter((item) => item)

    return output
  }

  const loadObjectListDataFirstTime = async (inputIsMounted, inputAbortController) => {
    setIsLoading(true)

    const resultTrackerList = await getTrackerList(
      inputAbortController.signal,
      auth.hashToken,
    )

    const resultTrackerStates = await postTrackerGetStates(
      inputAbortController.signal,
      { hash: auth.hashToken },
    )

    const resultTrackerGroupList = await getTrackerGroupList(
      inputAbortController.signal,
      auth.hashToken,
    )

    // SHOW DATA IF THE APIS WERE SUCCESSFULLY CALLED
    if (
      resultTrackerList.status === 200 && 
      resultTrackerStates.status === 200 && 
      resultTrackerGroupList.status === 200 &&
      inputIsMounted
    ) {
      const newTrackerList = resultTrackerList.data.list
      const stateObject = resultTrackerStates.data.states
      const groupList = resultTrackerGroupList.data.list
      
      // SET DATA GROUP TO CONTEXT
      setGroupList([
        ...groupList.map(item => ({
          id: item.id,
          groupColor: item.color,
          groupName: item.title.replace(/ /g, '-'),
        })),
        {
          id: 0,
          groupColor: theme.palette.primary.main,
          groupName: 'Main-Group'
        }
      ])

      setTrackerList(newTrackerList)

      const integratedList = integrateObjectData(newTrackerList, Object.values(stateObject), groupList)

      setObjectList(integratedList)
      setIsLoading(false)
    }
    // LOG OUT THE USER IF THE HASH TOKEN IS EXPIRED
    else if (
      isHashTokenExpired(resultTrackerList) || 
      isHashTokenExpired(resultTrackerStates) || 
      isHashTokenExpired(resultTrackerGroupList) 
    ) {
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: 'Your session has expired',
        message: 'Please log in again',
      })

      signOutUser(setAuth)
    }

    setIsDataAdded(true)
  }

  const updateObjectStateList = async (inputIsMounted) => {
    if (webSocketStateList.length > 0 && inputIsMounted) {
      const newObjectList = objectList.map(objectItem => {
        const stateObject = webSocketStateList.find(stateItem => {
          if (objectItem.source.id === stateItem.source_id) return stateItem
        })

        const latestPositions = [...objectItem.latestPositions]

        if (stateObject?.gps?.location?.lat && stateObject?.gps?.location?.lng) {
          if (referenceLocation.length > 0) {
            stateObject.distance_to_reference = getDistanceFromTwoLocations(
              referenceLocation,
              [ stateObject.gps.location.lat, stateObject.gps.location.lng ],
            )
          }

          latestPositions.push([ stateObject?.gps?.location?.lat, stateObject?.gps?.location?.lng ])
        }
  
        return {
          ...objectItem,
          state: stateObject ? { ...objectItem.state, ...stateObject, } : { ...objectItem.state },
          latestPositions,
        }
      })
      
      setObjectList(newObjectList)
    }
  }

  const handleClusterMenuItemClick = (inputItem) => {
    setObjectSelectionModel([ inputItem?.markerData?.id ])

    // SCROLL PANEL OBJECTS (DATA GRID) TO THE SELECTION ITEM
    const rowIndex = gridVisibleSortedRowIdsSelector(panelObjectsDataGridApiRef)
      .findIndex(id => id === inputItem?.markerData?.id)

    const dataGridHeight = panelObjectsDataGridApiRef.current.windowRef.current.clientHeight
    
    panelObjectsDataGridApiRef.current.scrollToIndexes({
      rowIndex: rowIndex + Math.floor(dataGridHeight / dataGridRowHeight) - 1,
      colIndex: 1,
    })
  }

  const subscribeToWebSocket = async (inputIsMounted, inputWebSocket, inputTimer) => {
    if (inputIsMounted) {
      const inputWebSocket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_BASE_URL}/websocket`)
      
      inputWebSocket.onopen = () => {
        inputWebSocket.send(JSON.stringify({
          action: 'subscribe',
          events: [ 'state' ],
          trackers: trackerList.map(item => item.id),
          hash: auth?.hashToken,
        }))
      }

      let tempStateList = []

      // ACTION INTERVAL
      const flush = () => {
        // SET TEMP STATE LIST TO SOCKET STATE
        setWebSocketStateList(tempStateList)

        // CLEAR TEMP STATE LIST
        tempStateList = []
      }

      inputTimer = setInterval(flush, 5000)
  
      inputWebSocket.onmessage = (message) => {
        const webSocketMessage = JSON.parse(message.data)

        if (webSocketMessage?.event === 'state' && webSocketMessage?.data) {
          tempStateList.push(webSocketMessage.data)
        }
      }
    }
  }

  const getAllPanelGeofencesBottomPosition = () => {
    if (isPanelDetailObjectShown) {
      if (isPanelDetailExpanded) return 340
      else return 120
    }
    else return 80
  }

  // UPDATE ACTION ANIMATION WITH VALUE
  const actionAnimationWithValue = (type, value) => {
    objectListInstance.forEach(item => {
      if(item?.instance) {
        if (type === 'hidePolylines') {
          // IF SELECT 1 OBJECT, DONT INCLUDE IN CLUSTER
          if (objectSelectionModel.length === 1) {
            const findItem = objectSelectionModel.find(objectId => objectId === item.id)
            if(Boolean(findItem)) {
              item.instance.hidePolylines(!moreList[2].isSelected) // change param to false, if just want show polyline for selected object
              return false
            } else item.instance.hidePolylines(value)
          } else {
            item.instance.hidePolylines(value)
          }
        } else if (type === 'hideMarkers') {
          // IF SELECT 1 OBJECT, DONT INCLUDE IN CLUSTER
          if (objectSelectionModel.length === 1) {
            const findItem = objectSelectionModel.find(objectId => objectId === item.id)
            if(Boolean(findItem)) {
              item.instance.getMarker().hideMarker(false)
              return false
            } else {
              item.instance.getMarker().hideMarker(value)
            }
          } else {
            item.instance.getMarker().hideMarker(value)
          }
        }
        else if (type === 'stopAll') item.instance.stop()
        else if (type === 'disableAllFollowMarker') item.instance?.getMarker()?.activeFollowMarker(value)
        else if (type === 'activeAnimMarker') item.instance?.getMarker()?.activeAnimate(value)
        else if (type === 'activeAnimPolyline') item.instance?.getCurrentPolyline()?.activeAnimate(value)
        else if (type === 'hideLabel') {
          const marker = item.instance?.getMarker()
          marker._icon.children[1].style.display = value ? 'block' : 'none'
        }
      }
    })
  }

  // CREATE A INSTANCE OF L.moveMarker
  const createInstance = (latLngs, inputItem) => {
    const backgroundColor = inputItem?.group?.color 
      ? '#' + inputItem.group.color.replace('#', '')
      : theme.palette.primary.main

    return L.moveMarker(latLngs,
      {
        animate: true,
        color: backgroundColor,
        weight: 4,
        hidePolylines: true,
        duration: 4000,
        removeFirstLines: true,
        maxLengthLines: 3,
      },
      {
        animate: true,
        hideMarker: true,
        duration: 4000,
        speed: 0,
        followMarker: false,
        rotateMarker: true,
        rotateAngle: 210,
        icon: MarkerIcon(classes, inputItem, backgroundColor)
      },
      {
  
      }).addTo(map)
  }

  // FOLLOW MARKER
  const followMarker = () => {
    // DISABLE ALL FOLLOW MARKER
    actionAnimationWithValue('disableAllFollowMarker', false)

    if(objectListInstance.length && objectSelectionModel.length === 1 && settingList[4].isSelected) {
      const findItem = objectListInstance.find(item => item?.id === objectSelectionModel[0]) || {}
      if (findItem?.instance) findItem.instance?.getMarker()?.activeFollowMarker(true)
    }
  }

  // SIDE EFFECT FIRST INIT TIME AND UPDATE INSTANCE
  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    // FIRST INIT (ONCE)
    !isDataAdded && loadObjectListDataFirstTime(isMounted, abortController)
    
    // UPDATE INSTANCE WHEN ALL READY
    if(isWebsocketRun && webSocketStateList.length) {
      webSocketStateList.forEach(itemState => {
        const findTracker = objectListInstance
          .filter(itemFilter => itemFilter !== undefined)
          .find(itemFind => itemFind.source.id === itemState.source_id)

        if (findTracker && itemState.gps.location.lat && itemState.gps.location.lng) {
          findTracker?.instance?.addMoreLine([
            itemState.gps.location.lat,
            itemState.gps.location.lng,
          ], {
            animatePolyline: isAnimatePolylinesRef.current,
            rotateAngle: itemState?.gps?.heading
          })
        }
      })

      updateObjectStateList(isMounted)
    }
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [webSocketStateList])
  
  useEffect(() => {
    let isMounted, timer, webSocket

    if(isWebsocketRun) {
      isMounted = true
      objectListInstance.length > 0 && subscribeToWebSocket(isMounted, webSocket, timer)
    }

    return () => {
      isMounted = false
      clearInterval(timer)
      if (webSocket) webSocket.close()
    }
  }, [auth?.hashToken, objectListInstance, isWebsocketRun])

  // SIDE EFFECT ADD OBJECTLIST TO MAP (ONCE)
  useEffect(() => {
    // ADD INSTANCE
    if(isDataAdded && objectList.length) {
      let tempObjectList = objectList.map(item => {
        if(item?.state?.gps?.location?.lat && item?.state?.gps?.location?.lng) {
          return {
            ...item,
            instance: createInstance([
              [
                parseFloat(item?.state?.gps?.location?.lat),
                parseFloat(item?.state?.gps?.location?.lng)
              ]
            ], item)
          }
        }
      })

      setObjectListInstance(tempObjectList)
      setIsWebsocketRun(true)
    }
  }, [isDataAdded])

  useEffect(() => {
    if (objectSelectionModel.length === 1) {
      setIsPanelDetailObjectShown(true)
      setIsPanelDetailExpanded(true)
    }
    else setIsPanelDetailObjectShown(false)
  }, [objectSelectionModel])

  useEffect(() => {
    if(objectListInstance.length) {
      // HIDE LABELS
      actionAnimationWithValue('hideLabel', moreList[1].isSelected)

      // TRACE
      actionAnimationWithValue('hidePolylines', !moreList[2].isSelected)

      // ANIMATION
      isAnimatePolylinesRef.current = moreList[3].isSelected
      actionAnimationWithValue('activeAnimMarker', moreList[3].isSelected)
      actionAnimationWithValue('activeAnimPolyline', moreList[3].isSelected)
    }
  }, [moreList])

  return (
    <Box 
      className={classes.pageRoot}
      ref={pageRef}
    >
      <MapContainer
        center={initialMapCenter}
        className={isAddingReferenceMarker
          ? `${classes.mapContainer} ${classes.mapContainerCrosshairCursor} no-zoom`
          : `${classes.mapContainer} no-zoom`}
        zoomControl={false}
        zoom={initialMapZoom}
        whenReady={(mapObject) => setMap(mapObject.target)}
        // FORCE TO RERENDER THE COMPONENT FOR UPDATING THE STYLES
        // INTENTIONALLY COMMENTED
        // key={isAddingReferenceMarker}
      >
        {/* BASE MAP */}
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
          subdomains={['mt1','mt2','mt3']}
          minZoom={minimalMapZoom}
          maxZoom={maximalMapZoom}
        />

        {/* MAP CAMERA */}
        <MapCamera
          locationItems={locationList}
          map={map}
          followMarker={followMarker}
          settingList={settingList}
        />

        {/* MARKERS AND CLUSTERS */}
        <Clustering
          objectListInstance={objectListInstance}
          groupList={groupList}
          actionAnimationWithValue={actionAnimationWithValue}
          moreList={moreList}
          objectSelectionModel={objectSelectionModel}
          setIsOpenPanelPieChart={setIsOpenPanelPieChart}
          webSocketStateList={webSocketStateList}
        />

        {/* LOCATION REFERENCE */}
        <MapLocationReference map={map}/>

        {/* INVALIDATE MAP SIZE */}
        <MapInvalidateSize 
          map={map}
          dependencyList={[ map, isDrawerExpanded ]}
        />

        {/* PIE CHART PANEL */}
        <MapClusterMenu
          isShowMenu={isOpenPanelPieChart}
          setIsShowMenu={setIsOpenPanelPieChart}
          onMenuItemClick={handleClusterMenuItemClick}
        />

        {/* GEOFENCE LIST */}
        <GeofenceList map={map}/>
      </MapContainer>

      {/* OBJECT PANEL CONTAINER */}
      <Box 
        className={classes.containerPanelObject}
        sx={{ bottom: (isPanelDetailObjectShown && isPanelDetailExpanded) ? 240 : 20 }}
      >
        {/* TOGGLE PANEL */}
        <PanelToggle
          pageRef={pageRef}
          isPanelShown={isObjectsPanelShown} 
          setIsPanelShown={setIsObjectsPanelShown}
          title='Objects'
        />

        {/* OBJECTS PANEL */}
        <PanelObjects/>
      </Box>

      {/* PANEL MENU */}
      <PanelMenu/>

      {/* PANEL GEOFENCES */}
      <PanelGeofences 
        map={map}
        getBottomPosition={getAllPanelGeofencesBottomPosition}
      />

      {/* PANEL CREATE OR EDIT GEOFENCE */}
      <PanelCreateOrEditGeofence getBottomPosition={getAllPanelGeofencesBottomPosition}/>

      {/* PANEL REFERENCE MARKER */}
      <PanelReferenceMarker/>

      {/* PANEL DETAIL OBJECT */}
      {isPanelDetailObjectShown && <PanelDetailObject/>}
    </Box>
  )
}

export default Tracking