// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingRight: 10,
  },
  input: {
    '& .MuiInputLabel-root': {
      fontSize: 16,
    },
    '& .MuiInputBase-input': {
      fontSize: 16,
    },
  },
}))

export default useStyles