// MUIS
import { alpha } from '@mui/material'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 72,
    position: 'relative',
    padding: '8px 12px',
  },
  headerToogle: {
    position: 'absolute',
    right: -12,
    width: 24,
    height: 24,
    cursor: 'pointer',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
  },
  textId: {
    fontWeight: 500,
    margin: '20px 0px',
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  navigationItem: {
    height: 48,
    borderRight: `4px solid ${theme.palette.primary.main}`,
    background: `linear-gradient(270deg, ${alpha(theme.palette.primary.main, 0.12)} 0%, ${alpha(theme.palette.primary.main, 0.02)} 100%)`,
    padding: '8px 16px 8px 24px',
  },
  navigationItemText: {
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
  },
  bottomItem: {
    borderRight: 'unset',
    background: 'unset',
  },
}))

export default useStyles