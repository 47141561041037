const degreeToRadian = (inputDegree) => {
  return inputDegree * (Math.PI / 180)
}

export const getDistanceFromTwoLocations = (inputLocation1, inputLocation2) => {
  let earthRadius = 6371 // EARTH RADIUS IN KM

  let degreeLatitude = degreeToRadian(inputLocation2[0] - inputLocation1[0])
  let degreeLongitude = degreeToRadian(inputLocation2[1] - inputLocation1[1])

  let a = 
    Math.sin(degreeLatitude/2) * Math.sin(degreeLatitude/2) +
    Math.cos(degreeToRadian(inputLocation1[0])) * Math.cos(degreeToRadian(inputLocation2[0])) * 
    Math.sin(degreeLongitude/2) * Math.sin(degreeLongitude/2)
     
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  let distance = earthRadius * c // DISTANCE IN KM
  return distance
}