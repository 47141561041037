import { useState, useEffect } from 'react'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconLock from '@mui/icons-material/Lock'
import IconVisibility from '@mui/icons-material/Visibility'
import IconVisibilityOff from '@mui/icons-material/VisibilityOff'

// STYLES
import useStyles from 'styles/layoutAuthentication'

// UTILS
import { isPasswordFormatValid } from 'utilities/validation'

const ResetPassword = () => {
  const classes = useStyles()

  const initialForm = {
    newPassword: '',
    confirmNewPassword : '',
  }

  const initialErrorForm = {
    newPassword: null,
    confirmNewPassword : null,
  }

  const [ form, setForm ] = useState(initialForm)
  const [ errorForm, setErrorForm ] = useState(initialErrorForm)
  const [ isRendered, setIsRendered ] = useState(false)
  const [ isButtonDisabled, setIsButtonDisabled ] = useState(true)
  const [ showPassword, setShowPassword ] = useState(false)

  const handleFormChange = (event) => {
    setForm(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  const handleButtonClick = async (inputEvent) => {
    inputEvent.preventDefault()
  }

  useEffect(() => {
    if(!isRendered) {
      setIsRendered(true)
      setErrorForm(initialErrorForm)
    }
  }, [])
  
  useEffect(() => {
    if (isRendered) {  
      if (!isPasswordFormatValid(form.confirmNewPassword)) {
        if (form.confirmNewPassword === '') {
          setErrorForm(current => ({
            ...current,
            confirmNewPassword: 'This field is required',
          }))
        }
        else {
          setErrorForm(current => ({
            ...current,
            confirmNewPassword: 'The minimum length for this field is 6',
          }))
        }
      }
      else {
        setErrorForm(current => ({
          ...current,
          confirmNewPassword: null,
        }))
      }
    }
  }, [form.confirmNewPassword])

  useEffect(() => {
    if (isRendered) {  
      if (!isPasswordFormatValid(form.newPassword)) {
        if (form.newPassword === '') {
          setErrorForm(current => ({
            ...current,
            newPassword: 'This field is required',
          }))
        }
        else {
          setErrorForm(current => ({
            ...current,
            newPassword: 'The minimum length for this field is 6',
          }))
        }
      }
      else {
        setErrorForm(current => ({
          ...current,
          newPassword: null,
        }))
      }
    }
  }, [form.newPassword])

  useEffect(() => {
    if (isRendered) {
      if (form.newPassword === '' || form.confirmNewPassword === '') setIsButtonDisabled(true)
      else if (Boolean(errorForm.newPassword) || Boolean(errorForm.confirmNewPassword)) setIsButtonDisabled(true)
      else setIsButtonDisabled(false)
    }
  }, [
    form.newPassword, form.confirmNewPassword, 
    errorForm.newPassword, errorForm.confirmNewPassword
  ])

  return (
    <form 
      className={classes.root} 
      onSubmit={handleButtonClick}
    >
      {/* TITLE */}
      <Typography 
        variant='h4'
        className={classes.textTitle}
      >
        Reset password?
      </Typography>

      {/* CAPTION */}
      <Typography
        variant='subtitle1'
        className={classes.textCaption}
      >
        Please enter your new password
      </Typography>

      {/* NEW PASSWORD INPUT */}
      <Box className={classes.formItem}>
        {/* ICON */}
        <IconLock className={classes.formItemIcon}/>

        {/* INPUT */}
        <FormControl 
          variant='standard'
          className={classes.input}
          error={Boolean(errorForm.newPassword)}
        >
          <InputLabel>
            Enter new password
          </InputLabel>

          <Input 
            type={showPassword ? 'text' : 'password'}
            name='newPassword'
            value={form.newPassword}
            onChange={handleFormChange}
            endAdornment={
              <InputAdornment 
                position='end'
                onClick={() => setShowPassword(current => !current)}
              >
                {showPassword
                  ? <IconVisibilityOff className={classes.iconPassword}/>
                  : <IconVisibility className={classes.iconPassword}/>}
              </InputAdornment>
            }
          />

          {/* ERROR TEXT */}
          <FormHelperText className={classes.inputHelper}>
            {errorForm.newPassword}
          </FormHelperText>
        </FormControl>
      </Box>

      {/* CONFIRM NEW PASSWORD INPUT */}
      <Box className={classes.formItem}>
        {/* ICON */}
        <IconLock className={classes.formItemIcon}/>

        {/* INPUT */}
        <FormControl 
          variant='standard'
          className={classes.input}
          error={Boolean(errorForm.confirmNewPassword)}
        >
          <InputLabel>
            Confirm new pasword
          </InputLabel>

          <Input 
            type={showPassword ? 'text' : 'password'}
            name='confirmNewPassword'
            value={form.confirmNewPassword}
            onChange={handleFormChange}
            endAdornment={
              <InputAdornment 
                position='end'
                onClick={() => setShowPassword(current => !current)}
              >
                {showPassword
                  ? <IconVisibilityOff className={classes.iconPassword}/>
                  : <IconVisibility className={classes.iconPassword}/>}
              </InputAdornment>
            }
          />

          {/* ERROR TEXT */}
          <FormHelperText className={classes.inputHelper}>
            {errorForm.confirmNewPassword}
          </FormHelperText>
        </FormControl>
      </Box>

      {/* CONTINUE BUTTON */}
      <Button 
        type='submit'
        disabled={isButtonDisabled}
        variant='contained'
        className={classes.button}
        disableElevation
        fullWidth
      >
        Continue
      </Button>
    </form>
  )
}

export default ResetPassword