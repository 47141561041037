// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
    boxShadow: theme.shadows[4],
    backgroundColor: theme.palette.background.paper,
    border: 'none',
  },
  button: {
    width: 40,
    height: 40,
  },
}))

export default useStyles