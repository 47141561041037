// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  rootMarker: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  markerArrow: {
    height: 20,
    width: 20,
    filter : 'drop-shadow(0px 0px 3px rgba(0,0,0,0.4))',
  },
  markerCircle: {
    height: 20,
    width: 20,
    filter : 'drop-shadow(0px 0px 3px rgba(0,0,0,0.4))',
  },
  text: {
    color: theme.palette.common.white,
    whiteSpace: 'nowrap',
    marginLeft: '8px !important',
    padding: '2px 8px',
    textAlign: 'center',
    opacity: 0.85,
    filter: 'drop-shadow(0px 0px 3px rgba(0,0,0,0.4))',
    fontWeight: 600,
  },
}))

export default useStyles