import { useEffect, useRef, useState } from 'react'

// COMPONENTS
import MarkerClusterIcon from 'components/MapMarkers/MarkerClusterIcon'

// LEAFLET
import L from 'leaflet'
import 'leaflet.markercluster/dist/leaflet.markercluster'
import { useMap } from 'react-leaflet'
import 'leaflet.motion/dist/leaflet.motion'

// SUPERCLUSTER
import Supercluster from 'supercluster'

// STYLES
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

// UTILITIES
import { updateMapBoundsAndZoom } from 'utilities/map'

const Clustering = (props) => {
  const {
    objectListInstance, groupList, actionAnimationWithValue, moreList,
    objectSelectionModel, setIsOpenPanelPieChart, webSocketStateList
  } = props

  const superclusterRef = useRef()
  const geoJsonRef = useRef()

  const map = useMap()

  // STATES
  const [ mapBounds, setMapBounds ] = useState()
  const [ mapZoom, setMapZoom ] = useState(5)

  // HANDLE CLUSTER PIE CHART CLICKED
  const handleMarkerPieChartClick = (event, clusterData) => {
    setIsOpenPanelPieChart({ mouseEvent: event, clusterData, superclusterRef })
  }

  // CREATE MARKER
  const createClusterIcon = (inputFeature, inputLatitudeLongitude) => {
    // DONT RENDER MARKER NORMAL
    if (!inputFeature?.properties?.cluster && inputLatitudeLongitude) return null
    // RENDER MARKER CLUSTER
    else {
      return L.marker(inputLatitudeLongitude, {
        icon: MarkerClusterIcon(
          { superclusterRef, detail: inputFeature }, 
          map, 
          groupList
        ),
      }).on('click', (event) => handleMarkerPieChartClick(event, inputFeature))
    }
  }

  // UPDATE MARKER LIST
  const updateCluster = () => {
    // CREATE REF GEOJSON FIRSTTIME
    if(!geoJsonRef.current) geoJsonRef.current = L.geoJSON(null, {
      pointToLayer: createClusterIcon
    }).addTo(map)
  
    // CLEAR PREVIOUS LAYERS
    geoJsonRef.current.clearLayers()
  
    // FILTER ONLY OBJECT HAVE LOCATION VALUE AND OFFLINE/ALL
    const markerList = objectListInstance.filter(item => {
      if(!item) return false
  
      // OBJECT HAS LOCATION VALUE
      if (item.state.gps.location.lat && item.state.gps.location.lng) {
        // DONT INCLUDE SELECTED OBJECT (1 SELECT)
        if(objectSelectionModel.length === 1) {
          const findItem = objectSelectionModel.find(objectId => objectId === item.id)
          if (Boolean(findItem)) return false
        }

        // moreList[4] - SHOW SELECTED OBJECT ONLY
        if(moreList[4].isSelected) {
          const findItem = objectSelectionModel.find(objectId => objectId === item.id)
          if(Boolean(findItem)) return item
          else return null
        }

        // HIDE OFFLINE OBJECTS (true)
        if(moreList[5].isSelected) {
          if(item.state.connection_status !== 'offline') return item
        } else {
          return item
        }
      }
      // OBJECT HAS NO LOCATION VALUE
      else return null
    })
  
    let pointList = markerList.map(item => ({
      type: 'Feature',
      properties: {
        cluster: false,
      },
      geometry: {
        type: 'Point',
        coordinates: [
          parseFloat(
            item.instance.getMarker()._nextLatLng
              ? item.instance.getMarker()._nextLatLng[1]
              : item.instance.getMarker().getLatLng().lng
          ) || null,
          parseFloat(
            item.instance.getMarker()._nextLatLng
              ? item.instance.getMarker()._nextLatLng[0]
              : item.instance.getMarker().getLatLng().lat
          ) || null,
        ],
      },
      markerData: item,
    }))
  
    // MORE LIST - CLUSTERIZE MARKERS
    let clusterRadius = moreList[0].isSelected ? 80 : 0
  
    superclusterRef.current = new Supercluster({
      radius: clusterRadius,
      extent: 256,
      maxZoom: 20,
    })
    superclusterRef.current.load(pointList)
  
    const clusterList = superclusterRef.current.getClusters(mapBounds, mapZoom)
  
    // ADD DATA CLUSTER LIST TO GEOJSON
    geoJsonRef.current.addData(clusterList)

    actionAnimationWithValue('hidePolylines', true)
    actionAnimationWithValue('hideMarkers', true)

    clusterList.forEach(item => {
      if(!item.properties.cluster) {
        item?.markerData?.instance?.hidePolylines(!moreList[2].isSelected)
        item?.markerData?.instance?.getMarker()?.hideMarker(false)
      }
    })
  }

  // MAP LISTENERS
  useEffect(() => {
    if(map) {
      if(!mapBounds) {
        updateMapBoundsAndZoom(map, setMapBounds, setMapZoom)
      }
  
      map.on('zoomend dragend', () => {
        updateMapBoundsAndZoom(map, setMapBounds, setMapZoom)
      })
    }
  }, [map])

  // UPDATE CLUSTER
  useEffect(() => {
    if(mapBounds && mapZoom && objectListInstance.length) {
      updateCluster()
    }
  }, [map, mapBounds, mapZoom, objectListInstance, moreList, webSocketStateList])


  return null
}

export default Clustering