import { useState, useContext, useEffect } from 'react'
import ReactDOMServer from 'react-dom/server'

// COMPONENTS
import MarkerReference from './MarkerReference'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// LEAFLET
import L from 'leaflet'
import { useMapEvents } from 'react-leaflet'

// STYLES
import useStyles from './mapLocationReferenceUseStyles'
import 'leaflet/dist/leaflet.css'

// UTILITIES
import { setReferenceLocationToLocalStorage } from 'utilities/localStorage'

const MapLocationReference = (props) => {
  const { map } = props

  const classes = useStyles()

  const {
    isAddingReferenceMarker, setIsAddingReferenceMarker,
    settingList, 
    referenceLocation, setReferenceLocation, 
  } = useContext(PageTrackingContext)
  
  const [ referenceMarker, setReferenceMarker ] = useState(null)

  const processReferenceMarker = (inputNewLocation) => {
    const newReferenceMarker = L.marker(inputNewLocation, {
      icon: L.divIcon({
        html: ReactDOMServer.renderToString(
          <MarkerReference classes={classes}/>
        ),
      })
    }).addTo(map)
    
    setReferenceMarker(newReferenceMarker)
    setReferenceLocation(inputNewLocation)
    setReferenceLocationToLocalStorage(inputNewLocation)
    setIsAddingReferenceMarker(false)
  }

  // MAP LISTENER
  useMapEvents({
    // ON MAP CLICK
    click: (event) => {
      const newReferenceLocation = [ event.latlng.lat, event.latlng.lng ]

      // SHOW THE REFERENCE MARKER ON THE MAP IF THE MAP IS CLICKED AND THERE IS NO REFERENCE MARKER YET
      if (
        referenceLocation.length === 0 && 
        (settingList[3].isSelected || isAddingReferenceMarker) && 
        !referenceMarker
      ) processReferenceMarker(newReferenceLocation)
    }
  })

  useEffect(() => {
    // SHOW THE REFERENCE MARKER ON THE MAP FROM THE LOCAL STORAGE
    if (map && referenceLocation.length === 2 && !referenceMarker) {
      processReferenceMarker(referenceLocation)
    }
    // REMOVE THE EXISTING REFERENCE MARKER FROM THE MAP
    else if (map && referenceLocation.length === 0 && referenceMarker) {
      map.removeLayer(referenceMarker)
    }
  }, [map, referenceLocation, referenceMarker])

  // CAN'T GET THE NEWEST STATE USING THIS CODE
  // useEffect(() => {
  //   if (map) {
  //     map.on('click', (event) => {
  //       const newReferenceLocation = [ event.latlng.lat, event.latlng.lng ]
  //       processReferenceMarker(newReferenceLocation)
  //     })
  //   }
  // }, [map, referenceLocation, referenceMarker])

  return null
}

export default MapLocationReference