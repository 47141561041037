import { useContext } from 'react'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// CUSTOM COMPONENTS
import CustomTooltip from 'components/Customs/CustomTooltip'

// MUIS
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

// MUI ICONS
import IconCropSquare from '@mui/icons-material/CropSquare'

// STYLES
import useStyles from './panelMenuUseStyles'

const PanelMenu = () => {
  const classes = useStyles()

  const { setIsPanelGeofencesShown } = useContext(PageTrackingContext)

  return (
    <ButtonGroup 
      variant='text'
      className={classes.root}
      color='inherit'
    >
      {/* GEOFENCES */}
      <CustomTooltip
        title='Geofences'
        placement='bottom'
      >
        <Button 
          className={classes.button}
          onClick={() => setIsPanelGeofencesShown(current => !current)}
        >
          <IconCropSquare color='action'/>
        </Button>
      </CustomTooltip>
    </ButtonGroup>
  )
}

export default PanelMenu