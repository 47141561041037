export const getZoneDetail = async (
  inputAxiosPrivate,
  inputSignal,
  inputBodyParams,
) => {
  try {
    const response = await inputAxiosPrivate.get(
      '/zone/point/list',
      { 
        signal: inputSignal,
        params: inputBodyParams, 
      },
    )

    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getZoneList = async (
  inputAxiosPrivate,
  inputSignal,
  inputBodyParams,
) => {
  try {
    const response = await inputAxiosPrivate.get(
      '/zone/list',
      { 
        signal: inputSignal,
        params: inputBodyParams, 
      },
    )

    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}