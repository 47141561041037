// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    zIndex: 1000,
  },
  objectList: {
    backgroundColor: theme.palette.common.white,
    width: 260,
    maxHeight: 400,
    overflowY: 'auto',
    '& .MuiTypography-root': {
      lineHeight: 1,
      fontSize: 12,
      width: '100%',
    },
  },
  objectItem: {
    padding: 8,
    borderLeft: '4px solid transparent',
  },
}))

export default useStyles