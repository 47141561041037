import { useState, useContext } from 'react'

// COMPONENTS
import CardDeviceModel from './CardDeviceModel'
import CardLocation from './CardLocation'
import CardStatus from './CardStatus'
import CardStreetView from './CardStreetView'
import DialogStreetView from './DialogStreetView'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import IconExpandLess from '@mui/icons-material/ExpandLess'

// STYLES
import useStyles from './panelDetailObjectUseStyles'

const PanelDetailObject = () => {
  const classes = useStyles()

  const { 
    objectList,
    objectSelectionModel, 
    setIsPanelDetailObjectShown,
    isPanelDetailExpanded, setIsPanelDetailExpanded,
  } = useContext(PageTrackingContext)

  const [ isDialogStreetViewOpen, setIsDialogStreetViewOpen ] = useState(false)

  let selectedObject = {}
  if (objectList.length > 0 && objectSelectionModel.length === 1) {
    selectedObject = objectList.find(item => item.id === objectSelectionModel[0])
  }

  let ExpandOrCollapseIcon = IconExpandLess
  if (isPanelDetailExpanded) ExpandOrCollapseIcon = IconExpandMore

  return (
    <Stack className={classes.root}>
      {/* TOGGLE PANEL */}
      <Stack 
        direction='row'
        alignItems='center'
        className={classes.togglePanel}
      >
        {/* EXPAND/COLLAPSE ICON */}
        <ExpandOrCollapseIcon 
          fontSize='small'
          className='cursorPointer'
          onClick={() => setIsPanelDetailExpanded(current => !current)}
        />

        {/* LABEL */}
        <Typography
          variant='body2'
          className={classes.togglePanelLabel}
        >
          {selectedObject?.label}
        </Typography>

        {/* CLOSE ICON */}
        <IconClose 
          fontSize='small'
          className='cursorPointer'
          onClick={() => setIsPanelDetailObjectShown(false)}
        />
      </Stack>

      {/* CONTENT */}
      {isPanelDetailExpanded &&
      <Stack
        direction='row'
        className={classes.content}
        flex='1'
        spacing='12px'
      >
        {/* STATUS CARD */}
        <CardStatus selectedObject={selectedObject}/>

        {/* DEVICE MODEL CARD */}
        <CardDeviceModel selectedObject={selectedObject}/>

        {/* STREET VIEW */}
        <CardStreetView 
          selectedObject={selectedObject}
          setIsDialogStreetViewOpen={setIsDialogStreetViewOpen}
        />

        {/* LOCATION CARD */}
        <CardLocation selectedObject={selectedObject}/>
      </Stack>}

      {/* DIALOG STREET VIEW */}
      <DialogStreetView
        selectedObject={selectedObject}
        isDialogStreetViewOpen={isDialogStreetViewOpen}
        setIsDialogStreetViewOpen={setIsDialogStreetViewOpen}
      />
    </Stack>
  )
}

export default PanelDetailObject