import { useState, useEffect, useContext } from 'react'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconLock from '@mui/icons-material/Lock'
import IconPerson from '@mui/icons-material/Person'
import IconVisibility from '@mui/icons-material/Visibility'
import IconVisibilityOff from '@mui/icons-material/VisibilityOff'

// SERVICES
import { postAuthenticateUser } from 'services/base/user'
import { postAuthenticateLacakUser } from 'services/entLacak/auth'

// STYLES
import useLayoutStyles from 'styles/layoutAuthentication'

// UTILS
import { setUserProfileToLocalStorage } from 'utilities/localStorage'
import { getDefaultErrorMessage } from 'utilities/string'
import { 
  isEmailFormatValid, 
  isEqualWith20X,
  isPasswordFormatValid, 
} from 'utilities/validation'

const SignIn = () => {
  const layoutClasses = useLayoutStyles()

  const { 
    setAuth,
    setSnackbarObject, 
  } = useContext(AllPagesContext)

  const initialSignInForm = {
    email: '',
    password: '',
  }

  const initialErrorForm = {
    email: null,
    password: null,
  }

  const [ signInForm, setSignInForm ] = useState(initialSignInForm)
  const [ errorForm, setErrorForm ] = useState(initialErrorForm)
  const [ showPassword, setShowPassword ] = useState(false)
  const [ isRendered, setIsRendered ] = useState(false)
  const [ isButtonDisabled, setIsButtonDisabled ] = useState(true)

  const handleSignInFormChange = (event) => {
    setSignInForm(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSignInButtonClick = async (inputEvent) => {
    inputEvent.preventDefault()
    
    const abortController = new AbortController()

    if (isEmailFormatValid(signInForm.email) && isPasswordFormatValid(signInForm.password)) {
      const resultAuthenticateUser = await postAuthenticateUser(
        abortController.signal,
        {
          login: signInForm.email,
          password: signInForm.password,
        }
      )

      const resultAuthenticateLacakUser = await postAuthenticateLacakUser()

      // SHOW SUCCESS MESSAGE AFTER SUCCESSFULLY SIGNING THE USER
      if (
        isEqualWith20X(resultAuthenticateUser.status) && 
        isEqualWith20X(resultAuthenticateLacakUser.status)
      ) {
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Successfully signed in'
        })

        const userObject = {
          email: signInForm.email,
          hashToken: resultAuthenticateUser.data.hash,
          hashTokenLacak: resultAuthenticateLacakUser.data.hash,
        }

        setUserProfileToLocalStorage(userObject)
        setAuth(userObject)
      }
      // SHOW ERROR MESSAGE AFTER FAILED TO SIGNING THE USER
      else setSnackbarObject({
        open: true,
        severity: 'error',
        title:'Failed to sign in',
        message: getDefaultErrorMessage(resultAuthenticateUser)
      })
    }

    abortController.abort()
  }

  useEffect(() => {
    if (!isRendered) {
      setIsRendered(true)
      setErrorForm(initialErrorForm)
    }
  }, [])
  
  useEffect(() => {
    if (isRendered) {
      if (!isEmailFormatValid(signInForm.email)) {
        if (signInForm.email === '') {
          setErrorForm(current => ({
            ...current,
            email: 'This field is required',
          }))
        }
        else {
          setErrorForm(current => ({
            ...current,
            email: 'E-mail must be valid',
          }))
        }
      }
      else {
        setErrorForm(current => ({
          ...current,
          email: null,
        }))
      }
    }
  }, [signInForm.email])

  useEffect(() => {
    if (isRendered) {  
      if (!isPasswordFormatValid(signInForm.password)) {
        if (signInForm.password === '') {
          setErrorForm(current => ({
            ...current,
            password: 'This field is required',
          }))
        }
        else {
          setErrorForm(current => ({
            ...current,
            password: 'The minimum length for this field is 6',
          }))
        }
      }
      else {
        setErrorForm(current => ({
          ...current,
          password: null,
        }))
      }
    }
  }, [signInForm.password])

  useEffect(() => {
    if (isRendered) {
      if (signInForm.email === '' || signInForm.password === '') setIsButtonDisabled(true)
      else if (Boolean(errorForm.email) || Boolean(errorForm.password)) setIsButtonDisabled(true)
      else setIsButtonDisabled(false)
    }
  }, [
    signInForm.email, signInForm.password, 
    errorForm.email, errorForm.password
  ])

  return (
    <form 
      className={layoutClasses.root} 
      onSubmit={handleSignInButtonClick}
    >
      {/* TITLE */}
      <Typography 
        variant='h4'
        className={layoutClasses.textTitle}
      >
        Welcome back!
      </Typography>

      {/* CAPTION */}
      <Typography
        variant='subtitle1'
        className={layoutClasses.textCaption}
      >
        Please enter your details.
      </Typography>

      {/* EMAIL INPUT */}
      <Box className={layoutClasses.formItem}>
        {/* ICON */}
        <IconPerson className={layoutClasses.formItemIcon}/>

        {/* INPUT */}
        <FormControl 
          variant='standard'
          className={layoutClasses.input}
          error={Boolean(errorForm.email)}
        >
          <InputLabel>
            Username or Email Address
          </InputLabel>

          <Input 
            autoFocus
            name='email'
            value={signInForm.email}
            onChange={handleSignInFormChange}
          />

          {/* ERROR TEXT */}
          <FormHelperText className={layoutClasses.inputHelper}>
            {errorForm.email}
          </FormHelperText>
        </FormControl>
      </Box>

      {/* PASSWORD INPUT */}
      <Box className={layoutClasses.formItem}>
        {/* ICON */}
        <IconLock className={layoutClasses.formItemIcon}/>

        {/* INPUT */}
        <FormControl 
          variant='standard'
          className={layoutClasses.input}
          error={Boolean(errorForm.password)}
        >
          <InputLabel>
            Password
          </InputLabel>

          <Input 
            type={showPassword ? 'text' : 'password'}
            name='password'
            value={signInForm.password}
            onChange={handleSignInFormChange}
            endAdornment={
              <InputAdornment 
                position='end'
                onClick={() => setShowPassword(current => !current)}
              >
                {showPassword
                  ? <IconVisibilityOff className={layoutClasses.iconPassword}/>
                  : <IconVisibility className={layoutClasses.iconPassword}/>}
              </InputAdornment>
            }
          />

          {/* ERROR TEXT */}
          <FormHelperText className={layoutClasses.inputHelper}>
            {errorForm.password}
          </FormHelperText>
        </FormControl>
      </Box>

      {/* FORGOT PASSWORD LINK */}
      <Link
        href='/forgot-password'
        underline='none'
        color='secondary'
        className={layoutClasses.link}
      >
        Forgot Password?
      </Link>

      {/* SIGN IN BUTTON */}
      <Button 
        type='submit'
        disabled={isButtonDisabled}
        variant='contained'
        className={layoutClasses.button}
        disableElevation
        fullWidth
      >
        Login
      </Button>
    </form>
  )
}

export default SignIn