// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 372,
    position: 'absolute',
    top: 80,
    left: 20,
    zIndex: 1,
    boxShadow: theme.shadows[4],
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
  },
  header: {
    height: 56,
    padding: '0px 8px',
  },
  headerIconContainer: {
    marginLeft: 2,
    marginRight: 8,
    width: 40,
    alignItems: 'center',
  },
  dataGrid: {
    '& .MuiDataGrid-row': {
      borderLeft: '4px solid transparent',
    },
  },
  speedDial: {
    position: 'absolute', 
    bottom: 16, 
    right: 16,
  },
}))

export default useStyles