export const updateMapBoundsAndZoom = (
  inputMap,
  inputSetBounds,
  inputSetZoom,
) => {
  if (inputMap) {
    const b = inputMap.getBounds()
    
    inputSetBounds([
      b.getSouthWest().lng,
      b.getSouthWest().lat,
      b.getNorthEast().lng,
      b.getNorthEast().lat
    ])
    
    inputSetZoom(inputMap.getZoom())
  }
}