import { useState, useEffect, useContext } from 'react'

// COMPONENTS
import CustomDataGridPanel from 'components/Customs/CustomDataGridPanel'
import DataGridCell from './DataGridCell'
import DataGridGroupingHeader from 'components/DataGridGroupingHeader/DataGridGroupingHeader'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// HOOKS
import useAxiosPrivateLacak from 'hooks/useAxiosPrivateLacak'

// MUIS
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// MUI ICONS
import IconAdd from '@mui/icons-material/Add'
import IconClose from '@mui/icons-material/Close'
import IconCropFree from '@mui/icons-material/CropFree'
import IconCropSquare from '@mui/icons-material/CropSquare'
import IconRadioButtonChecked from '@mui/icons-material/RadioButtonChecked'

// SERVICES
import { 
  getZoneDetail,
  getZoneList, 
} from 'services/entLacak/zone'

// STYELS
import useStyles from './panelGeofencesUseStyles'

const PanelGeofences = (props) => {
  const { 
    map, 
    getBottomPosition,
  } = props

  const classes = useStyles()

  const axiosPrivateLacak = useAxiosPrivateLacak()

  const { 
    isPanelGeofencesShown, setIsPanelGeofencesShown, 
    geofenceSelectionModel, setGeofenceSelectionModel,
    setSelectedGeofenceList,
    setHasGeofenceListFinishedLoading,
    panelGeofencesDataGridApiRef,
  } = useContext(PageTrackingContext)

  const theme = useTheme()

  const geofenceSpeedDialChildrenList = [
    {
      icon: IconCropFree,
      name: 'New Geofence (Polygon)',
      command: 'Polygon',
    },
    {
      icon: IconRadioButtonChecked,
      name: 'New Geofence (Circle)',
      command: 'Circle',
    },
  ]

  const columnList = [
    {
      field: 'label',
      headerName: '',
      sortable: false,
      renderCell: (params) => <DataGridCell {...params}/>,
      renderHeader: (params) => (
        <DataGridGroupingHeader 
          {...params}
          search={search}
          setSearch={setSearch}
        />
      ),
    },
  ]

  const [ search, setSearch ] = useState('')
  const [ geofenceList, setGeofenceList ] = useState([])

  const sortListNumerically = (inputList) => { return inputList.sort((a, b) => a - b) }

  const handleCellClick = (inputParams, inputEvent, inputDetails) => {
    // CELL IS CLICKED
    if (Number.isInteger(inputParams.id) && inputParams.field !== '__check__') {
      setGeofenceSelectionModel([ inputParams.id ])
    }
    // CELL CHECKBOX IS CLICKED
    else if (Number.isInteger(inputParams.id) && inputParams.field === '__check__') {
      if(geofenceSelectionModel.length === 0) setGeofenceSelectionModel([ inputParams.id ])
      else {
        let newGeofenceSelectionModel = [...geofenceSelectionModel]

        if(geofenceSelectionModel.includes(inputParams.id)) newGeofenceSelectionModel = newGeofenceSelectionModel.filter(item => item !== inputParams.id)
        else {
          newGeofenceSelectionModel.push(inputParams.id)
          sortListNumerically(newGeofenceSelectionModel)
        }

        setGeofenceSelectionModel(newGeofenceSelectionModel)
      }
    }
  }

  const handleColumnHeaderClick = (inputParams, inputEvent, inputDetails) => {
    // CHECKBOX IS CLICKED
    if(inputParams.field === '__check__') {
      if(geofenceSelectionModel.length === 0) setGeofenceSelectionModel(panelGeofencesDataGridApiRef.current.getAllRowIds())
      else setGeofenceSelectionModel([]) 
    }
    // NON-CHECKBOX IS CLICKED
    else return null
  }

  const getGeofenceListData = async (inputIsMounted, inputAbortController) => {
    const resultGeofenceList = await getZoneList(
      axiosPrivateLacak,
      inputAbortController.signal,
      {
        sort: [ 'label=asc' ],
        offset: 0,
        limit: 100,
      },
    )

    if (resultGeofenceList.status === 200 && inputIsMounted) {
      setGeofenceList(resultGeofenceList.data.list.filter(item => item.type !== 'sausage'))
    }
  }

  const getGeofenceDetailData = async (inputIsMounted, inputAbortController) => {
    setHasGeofenceListFinishedLoading(false)

    let newSelectedGeofenceList = geofenceList.filter(item => geofenceSelectionModel.includes(item.id))

    for (const [index, item] of newSelectedGeofenceList.entries()) {
      if (item.type === 'polygon') {
        // GET POINT LIST FROM POLYGON TYPE GEOFENCE
        const resultGeofenceDetail = await getZoneDetail(
          axiosPrivateLacak,
          inputAbortController.signal,
          { zone_id: item.id },
        )

        // ADD POINT LIST TO THE SELECTED GEOFENCE LIST
        if (resultGeofenceDetail.status === 200 && inputIsMounted) {
          newSelectedGeofenceList[index].pointList = resultGeofenceDetail.data.list
        }
      }
    }

    setSelectedGeofenceList(newSelectedGeofenceList)
    setHasGeofenceListFinishedLoading(true)
  }

  const handleCreateGeofence = (inputType) => {
    map.pm.enableDraw(inputType, {
      snappable: true,
      templineStyle: {
        color: theme.palette.primary.main,
        weight: 3,
        opacity: 0.2,
      },
      hintlineStyle: {
        color: theme.palette.primary.main,
        weight: 3,
        opacity: 0.2,
        dashArray: [5, 5],
      },
      pathOptions: {
        color: theme.palette.primary.main,
        fillColor: theme.palette.primary.main,
        fillOpacity: 0.2,
        opacity: 0.2,
      },
      cursorMarker: false,
      finishOnDoubleClick: true,
    })
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    getGeofenceListData(isMounted, abortController)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    geofenceSelectionModel.length > 0 && getGeofenceDetailData(isMounted, abortController)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [geofenceSelectionModel])

  return (
    <Fade in={isPanelGeofencesShown}>
      <Stack 
        className={classes.root}
        sx={{ bottom: getBottomPosition() }}
      >
        {/* HEADER */}
        <Stack 
          direction='row'
          alignItems='center'
          className={classes.header}
        >
          {/* ICON */}
          <Stack className={classes.headerIconContainer}>
            <IconCropSquare/>
          </Stack>
  
          {/* PANEL TITLE */}
          <Typography
            variant='h6'
            flex='1'
          >
            Geofences
          </Typography>

          {/* TOGGLE COLLAPSE SETTINGS */}
          <IconButton onClick={() => setIsPanelGeofencesShown(current => !current)}>
            <IconClose/>
          </IconButton>
        </Stack>

        {/* GEOFENCES DATA GRID */}
        <CustomDataGridPanel
          // BASE
          apiRef={panelGeofencesDataGridApiRef}
          columns={columnList}
          rows={geofenceList}
          className={classes.dataGrid}
          // SELECTION
          selectionModel={geofenceSelectionModel}
          onCellClick={(params, event, details) => handleCellClick(params, event, details)}
          onColumnHeaderClick={(params, event, details) => handleColumnHeaderClick(params, event, details)}
        />

        {/* SPEED DIAL */}
        <SpeedDial
          ariaLabel='Geofence Speed Dial'
          className={classes.speedDial}
          icon={<IconAdd onClick={() => handleCreateGeofence('Circle')}/>}
        >
          {geofenceSpeedDialChildrenList.map((item, index) => (
            <SpeedDialAction
              key={index}
              icon={<item.icon fontSize='small'/>}
              tooltipTitle={item.name}
              onClick={() => handleCreateGeofence(item.command)}
            />
          ))}
        </SpeedDial>
      </Stack>
    </Fade>
  )
}

export default PanelGeofences