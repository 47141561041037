import { useContext } from 'react'

// ASSETS
import LogoWulingFinanceFull from 'assets/images/logos/wuling-finance-full.svg'
import LogoWulingFinanceShort from 'assets/images/logos/wuling-finance-short.svg'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { LayoutPrivateContext } from 'contexts/LayoutPrivateContext'

// CUSTOM COMPONENTS
import CustomPermanentDrawer from 'components/Customs/CustomPermanentDrawer'

// MUIS
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew'
import IconArrowForwardIos from '@mui/icons-material/ArrowForwardIos'
import IconLanguage from '@mui/icons-material/Language'
import IconLogout from '@mui/icons-material/Logout'

// STYLES
import useStyles from './drawerUseStyles'

// UTILITIES
import { signOutUser } from 'utilities/authentication'

const Drawer = () => {
  const classes = useStyles()

  const { setAuth } = useContext(AllPagesContext)
  const { isDrawerExpanded, setIsDrawerExpanded } = useContext(LayoutPrivateContext)

  return (
    <CustomPermanentDrawer 
      variant='permanent'
      open={isDrawerExpanded}
      className={`${classes.root} zoom`}
    >
      {/* HEADER */}
      <Box className={classes.header}>
        {/* COMPANY LOGO */}
        <Box
          component='img'
          src={isDrawerExpanded ? LogoWulingFinanceFull : LogoWulingFinanceShort}
          alt=''
          sx={{ height: isDrawerExpanded ? 36 : 28 }}
        />

        {/* EXPAND/COLLAPSE ICON */}
        <Avatar 
          className={classes.headerToogle}
          onClick={() => setIsDrawerExpanded(current => !current)}
        >
          {isDrawerExpanded 
            ? <IconArrowBackIosNew fontSize='small'/> 
            : <IconArrowForwardIos fontSize='small'/>
          }
        </Avatar>
      </Box>

      <Divider/>

      {/* ID TEXT */}
      <Typography
        variant='body2'
        className={classes.textId}
      >
        {isDrawerExpanded ? 'ID: #10011680' : 'ID'}
      </Typography>

      {/* NAVIGATION LIST */}
      <List disablePadding>
        <ListItemButton className={classes.navigationItem}>
          {/* ICON */}
          <ListItemIcon>
            <IconLanguage color='primary'/>
          </ListItemIcon>

          {/* TEXT */}
          {isDrawerExpanded &&
          <ListItemText 
            primary='Tracking' 
            className={classes.navigationItemText}
          />}
        </ListItemButton>
      </List>

      {/* BOTTOM LIST */}
      <List className='marginTopAuto'>
        <ListItemButton 
          className={`${classes.navigationItem} ${classes.bottomItem}`}
          onClick={() => signOutUser(setAuth)}
        >
          {/* ICON */}
          <ListItemIcon>
            <IconLogout/>
          </ListItemIcon>

          {/* TEXT */}
          {isDrawerExpanded &&
          <ListItemText 
            primary='Log Out' 
            className={classes.navigationItemText}
          />}
        </ListItemButton>
      </List>
    </CustomPermanentDrawer>
  )
}

export default Drawer