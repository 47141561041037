import { useState, useEffect, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'

// COMPONENTS
import MapCamera from 'components/MapCamera/MapCamera'
import MapClusterMenu from 'components/MapClusterMenu/MapClusterMenu'
import MapMarkers from 'components/MapMarkers/MapMarkers'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// LEAFLET
import { 
  MapContainer, 
  TileLayer, 
} from 'react-leaflet'

// MUIS
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

// SERVICES
import { 
  getTrackerGroupList,
  getTrackerList,
  postTrackerGetStates, 
} from 'services/base/tracker'

// STYLES
import useStyles from './locatorUseStyles'
import 'leaflet/dist/leaflet.css'

// UTILITIES
import { signOutUser } from 'utilities/authentication'
import { isHashTokenExpired } from 'utilities/validation'

const Locator = () => {
  const classes = useStyles()

  const { setAuth, setSnackbarObject } = useContext(AllPagesContext)

  const [ searchParams ] = useSearchParams()
  const theme = useTheme()

  const hashToken = searchParams.get('key')
  const objects = searchParams.get('objects')
  
  const trackerIdList = objects.split(',').map(item => parseInt(item))

  const initialMapCenter = [-0.7893, 113.9213]
  const initialMapZoom = 5
  const minimalMapZoom = 2
  const maximalMapZoom = 20

  const [ markerList, setMarkerList ] = useState([])
  const [ map, setMap ] = useState()
  const [ groupList, setGroupList ] = useState([])
  const [ isOpenPanelPieChart, setIsOpenPanelPieChart] = useState(false)
  const [ locationList, setLocationList ] = useState([])

  const locationItems = [...markerList]
    .filter((item) => item?.state?.gps?.location?.lat !== null && item?.state?.gps?.location?.lng !== null )
    .map((item) => [item?.state?.gps?.location?.lat, item?.state?.gps?.location?.lng])

  const integrateObjectData = (
    inputTrackerList,
    inputStateList,
    inputGroupList
  ) => {
    let integratedList = inputTrackerList.map(trackerItem => {
      const stateObject = inputStateList.find(stateItem => {
        if (stateItem.source_id === trackerItem.source.id) {
          return stateItem
        }
      })

      const groupObject = inputGroupList.find((groupItem) => groupItem.id === trackerItem.group_id)

      return {
        ...trackerItem,
        state: stateObject ? { ...stateObject} : {},
        group: { ...groupObject },
      }
    })

    return integratedList.filter((item) => item)
  }

  const loadMarkerListDataFirstTime = async (inputIsMounted, inputAbortController) => {
    const resultTrackerList = await getTrackerList(
      inputAbortController.signal,
      hashToken,
    )

    const resultTrackerStates = await postTrackerGetStates(
      inputAbortController.signal,
      {
        hash: hashToken,
        trackers: trackerIdList,
      },
    )

    const resultTrackerGroupList = await getTrackerGroupList(
      inputAbortController.signal,
      hashToken,
    )

    if (
      resultTrackerList.status === 200 && 
      resultTrackerStates.status === 200 && 
      resultTrackerGroupList.status === 200 &&
      inputIsMounted
    ) {
      // SET DATA GROUP 
      setGroupList([
        ...resultTrackerGroupList.data.list.map(item => ({
          id: item.id,
          groupColor: item.color,
          groupName: item.title.replace(/ /g, '-'),
        })),
        {
          id: 0,
          groupColor: theme.palette.primary.main,
          groupName: 'Main-Group'
        }
      ])

      const trackerList = resultTrackerList.data.list
      const stateObject = resultTrackerStates.data.states
      const groupList = resultTrackerGroupList.data.list

      let newTrackerList = []

      trackerIdList.forEach(oldItem => {
        const filteredTrackerList = trackerList.filter(newItem => newItem.id === oldItem)
        newTrackerList.push(filteredTrackerList[0])
      })

      setMarkerList(integrateObjectData(newTrackerList, Object.values(stateObject), groupList))
    }
    else if (
      isHashTokenExpired(resultTrackerList) || 
      isHashTokenExpired(resultTrackerStates) || 
      isHashTokenExpired(resultTrackerGroupList)
    ) {
      signOutUser(setAuth)
      setSnackbarObject({
        open: true,
        severity: 'error',
        title:'Invalid key',
        message: resultTrackerList?.data?.detailMessage
      })
    }
  }

  const updateMarkerStateList = async (inputIsMounted, inputAbortController) => {
    const resultTrackerStates = await postTrackerGetStates(
      inputAbortController.signal,
      {
        hash: hashToken,
        trackers: trackerIdList,
      },
    )

    if (resultTrackerStates.status === 200 && inputIsMounted) {
      const newStateList = Object.values(resultTrackerStates.data.states)
  
      const newMarkerList = markerList.map(markerItem => {
        const stateObject = newStateList.find(stateItem => {
          if (stateItem.source_id === markerItem.source.id) return stateItem
        })

        return {
          ...markerItem,
          state: stateObject ? {...stateObject} : {},
        }
      })

      setMarkerList(newMarkerList)
    }
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadMarkerListDataFirstTime(isMounted, abortController)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    const udpateMarkerList = setTimeout(() => {
      updateMarkerStateList(isMounted, abortController)
    }, process.env.REACT_APP_API_REQUEST_INTERVAL * 1000)

    return () => {
      clearTimeout(udpateMarkerList)
      isMounted = false
      abortController.abort()
    }
  }, [markerList])

  return (
    <Box className={`${classes.pageRoot} no-zoom`}>
      <MapContainer
        preferCanvas={true}
        center={initialMapCenter}
        className={classes.mapContainer}
        zoomControl={false}
        zoom={initialMapZoom}
        whenReady={(mapObject) => setMap(mapObject.target)}
      >
        {/* BASE MAP */}
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          subdomains='abc'
          minZoom={minimalMapZoom}
          maxZoom={maximalMapZoom}
        />
        
        {/* MARKERS AND CLUSTERS */}
        <MapMarkers
          objectList={markerList}
          groupList={groupList}
          setIsOpenPanelPieChart={setIsOpenPanelPieChart}
        />

        {/* MAP CAMERA */}
        <MapCamera
          locationItems={locationList.length === 0 ? locationItems : locationList}
          map={map}
          followMarker={() => null}
        />

        {/* PIE CHART PANEL */}
        <MapClusterMenu
          isShowMenu={isOpenPanelPieChart}
          setIsShowMenu={setIsOpenPanelPieChart}
          onMenuItemClick={(item) => setLocationList([[
            item?.markerData?.state?.gps?.location.lat,
            item?.markerData?.state?.gps?.location.lng,
          ]])}
        />

      </MapContainer>
    </Box>
  )
}

export default Locator