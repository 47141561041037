// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// MUI DATA GRID
import {
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro'

// MUI ICONS
import IconCircle from '@mui/icons-material/Circle'
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'

// STYLES
import useStyles from './panelObjectUseStyles'

// UTILITIES
import { getConnectionStatusObject } from 'utilities/color'

const GroupingCell = (props) => {
  const classes = useStyles()

  const theme = useTheme()

  const gridApiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(
    gridApiRef,
    gridFilteredDescendantCountLookupSelector
  )

  const childrenCount = filteredDescendantCountLookup[props.rowNode.id] ?? 0

  const handleGroupClick = (inputEvent) => {
    gridApiRef.current.setRowChildrenExpansion(
      props.id,
      !props.rowNode.childrenExpanded
    )
    gridApiRef.current.setCellFocus(props.id, props.field)
    inputEvent.stopPropagation()
  }

  // GROUP ITEM
  if (!props.rowNode.isAutoGenerated) {
    return (
      <Stack
        direction='row'
        alignItems='center'
        className={classes.cellItemContainer}
      >
        {/* LABEL */}
        <Typography
          variant='inherit'
          color='text.primary'
          noWrap
          className={classes.cellItemLabel}
        >
          {props.row.label}
        </Typography>

        {/* SPEED */}
        <Typography
          variant='inherit'
          color='text.secondary'
          className={classes.cellItemSpeed}
        >
          {props.row.state?.gps?.speed ? `${props.row.state?.gps?.speed} km/h` : ''}
        </Typography>

        {/* STATUS */}
        <IconCircle 
          className={classes.cellItemIconStatus}
          sx={{ color: getConnectionStatusObject(props.row.state?.connection_status, theme) }}
        />
      </Stack>
    )
  }

  // GROUP TITLE
  return (
    <Stack 
      direction='row'
      alignItems='center'
      onClick={handleGroupClick}
      className={classes.cellGroup}
    >
      {/* TEXT */}
      <Typography
        variant='inherit'
        noWrap
        className={classes.cellGroupText}
      >
        {`${props.rowNode.groupingKey} (${childrenCount})`}
      </Typography>

      {/* EXPAND/COLLAPSE ICON */}
      {props.rowNode.childrenExpanded 
        ? <IconExpandLess className={classes.cellGroupIcon}/> 
        : <IconExpandMore className={classes.cellGroupIcon}/>
      }
    </Stack>
  )
}

export default GroupingCell