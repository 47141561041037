// APIS
import axios from 'apis/axiosBase'

// QUERY
import { stringify } from 'query-string'

export const getAddressFromLatitudeAndLongitude = async (
  inputSignal,
  inputQueryParams,
) => {
  try {
    const response = await axios.get(
      `/geocoder/search_location?${stringify(inputQueryParams)}`,
      { signal: inputSignal },
    )

    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}