// CONTEXTS
import { PageTrackingContextProvider } from 'contexts/PageTrackingContext'

// PAGES
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword'
import Locator from 'pages/Locator/Locator'
import ResetPassword from 'pages/ResetPassword/ResetPassword'
import SignIn from 'pages/SignIn/SignIn'
import Tracking from 'pages/Tracking/Tracking'

const routes = [
  // AUTHENTICATION
  {
    path: '/sign-in',
    element: <SignIn/>,
    routeType: 'authentication',
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword/>,
    routeType: 'authentication',
  },
  {
    path: '/change-password',
    element: <ResetPassword/>,
    routeType: 'authentication',
  },
  // PRIVATE
  {
    path: '/',
    element: (
      <PageTrackingContextProvider>
        <Tracking/>
      </PageTrackingContextProvider>
    ),
    routeType: 'private',
  },
  // FREE
  {
    path: '/locator',
    element: <Locator/>,
    routeType: 'free',
  },
]

export default routes