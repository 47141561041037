import PropTypes from 'prop-types'

// ASSETS
import LogoWulingFinance from 'assets/images/logos/wuling-finance-full.svg'

// MUIS
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

// STYLES
import useStyles from './authenticationUseStyles'

const Authentication = (props) => {
  const { children } = props

  const classes = useStyles()

  return (
    <Grid 
      container
      className={`${classes.root} no-zoom`}
    >
      {/* LEFT CONTENT */}
      <Grid
        item
        xs={4}
        className={`${classes.leftContent} zoom`}
      >
        {/* COMPANY LOGO */}
        <Box
          component='img'
          src={LogoWulingFinance}
          alt=''
          className={classes.companyLogo}
        />

        {/* BACKGROUND */}
        <Box className={classes.backgroundImage}/>
      </Grid>

      {/* RIGHT CONTENT */}
      <Grid
        item
        xs={8}
        className={`${classes.rightContent} zoom`}
      >
        {/* CHILDREN */}
        {children}
      </Grid>
    </Grid>
  )
}

Authentication.defaultProps = {}

Authentication.propTypes = { children: PropTypes.node.isRequired }

export default Authentication