// TO DO: REPLACE THESE LOCAL STORAGE WITH COOKIES
const keyReferenceLocation = 'wuling-tracking-reference-location'
const keyUserProfile = 'wuling-tracking-user'

// REFERENCE LOCATION
export const setReferenceLocationToLocalStorage = (inputLocationObject) => {
  localStorage.setItem(keyReferenceLocation, JSON.stringify(inputLocationObject))
}

export const readReferenceLocationFromLocalStorage = () => {
  return localStorage.getItem(keyReferenceLocation)
    ? JSON.parse(localStorage.getItem(keyReferenceLocation))
    : []
}

export const removeReferenceLocationFromLocalStorage = () => {
  return localStorage.removeItem(keyReferenceLocation)
}

// USER PROFILE
export const setUserProfileToLocalStorage = (inputUserObject) => {
  localStorage.setItem(keyUserProfile, JSON.stringify(inputUserObject))
}

export const readUserProfileFromLocalStorage = () => {
  return localStorage.getItem(keyUserProfile)
    ? JSON.parse(localStorage.getItem(keyUserProfile))
    : {}
}

export const removeUserProfileFromLocalStorage = () => {
  return localStorage.removeItem(keyUserProfile)
}