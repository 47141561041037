import { useEffect, useRef, useState } from 'react'

// COMPONENTS
import MarkerIcon from './MarkerIcon'

// LEAFLET
import L from 'leaflet'
import 'leaflet.markercluster/dist/leaflet.markercluster'
import { useMap } from 'react-leaflet'
import 'leaflet.motion/dist/leaflet.motion'

// MUIS
import { useTheme } from '@mui/material/styles'

// STYLES
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import useStyles from './mapMarkersUseStyles'

// UTILITIES
import { updateMapBoundsAndZoom } from 'utilities/map'

const MapMarkers = (props) => {
  const {
    objectList,
    objectSelectionModel,
  } = props

  const classes = useStyles()
  const geoJsonRef = useRef(L.featureGroup())

  const theme = useTheme()

  const map = useMap()

  // STATES
  const [ mapBounds, setMapBounds ] = useState()
  const [ mapZoom, setMapZoom ] = useState(5)

  // CREATE MARKER
  const createMarker = (inputFeature, inputLatitudeLongitude) => {
    const backgroundColor = inputFeature?.group.color 
      ? '#' + inputFeature?.group?.color?.replace('#', '')
      : theme.palette.primary.main

    return L.marker(inputLatitudeLongitude, {
      icon: MarkerIcon(classes, inputFeature, backgroundColor),
    })
  }

  // UPDATE MARKER LIST
  const updateCluster = () => {
    geoJsonRef.current.clearLayers()

    if (objectList.length) {
      objectList.forEach(feature => {
        const marker = createMarker(feature, [feature.state.gps.location.lat, feature.state.gps.location.lng])
        geoJsonRef.current.addLayer(marker)
      })
    }

    map.addLayer(geoJsonRef.current)
  }

  // MAP LISTENERS
  useEffect(() => {
    if(map) {
      if(!mapBounds) {
        updateMapBoundsAndZoom(map, setMapBounds, setMapZoom)
      }
  
      map.on('zoomend dragend', () => {
        updateMapBoundsAndZoom(map, setMapBounds, setMapZoom)
      })
    }
  }, [map])

  // UPDATE CLUSTER
  useEffect(() => {
    if(mapBounds && mapZoom && objectList.length) {
      updateCluster()
    }
  }, [map, mapBounds, mapZoom, objectList, objectSelectionModel])


  return null
}

export default MapMarkers