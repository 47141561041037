import React from 'react'
import PropTypes from 'prop-types'

// CUSTOM COMPONENTS
import CustomTooltip from 'components/Customs/CustomTooltip'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'

// MUI ICONS
import IconList from '@mui/icons-material/List'

// STYLES
import useStyles from './panelToogleUseStyles'

const PanelToggle = (props) => {
  const {
    pageRef,
    isPanelShown, 
    setIsPanelShown,
    title,
  } = props

  const classes = useStyles()

  return (
    <Slide
      direction='left' 
      in={!isPanelShown} 
      container={pageRef.current}
      unmountOnExit
      mountOnEnter
    >
      <Box className={classes.root}>
        <CustomTooltip
          title={title}
          placement='bottom'
        >
          <IconButton onClick={() => setIsPanelShown(true)}>
            <IconList/>
          </IconButton>
        </CustomTooltip>
      </Box>
    </Slide>
  )
}

PanelToggle.defaultProps = {
  isPanelShown: true,
  title: '',
}

PanelToggle.propTypes = {
  pageRef: PropTypes.any.isRequired,
  isPanelShown: PropTypes.bool.isRequired, 
  setIsPanelShown: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}


export default PanelToggle