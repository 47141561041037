// CUSTOM COMPONENTS
import CustomTooltip from 'components/Customs/CustomTooltip'

// MUIS
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import Stack from '@mui/material/Stack'

// MUI ICONS
import IconClear from '@mui/icons-material/Clear'
import IconSearch from '@mui/icons-material/Search'

// STYLES
import useStyles from './dataGridGroupingHeaderUseStyles'

const DataGridGroupingHeader = (props) => {
  const { search, setSearch } = props

  const classes = useStyles()

  return (
    <Stack className={classes.root}>
      {/* TEXT FIELD */}
      <Input
        className={classes.input}
        variant='standard'
        placeholder='Search'
        type='text'
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        onClick={(event) => event.stopPropagation()}
        onKeyDown={(event) => event.stopPropagation()}
        disableUnderline
        fullWidth
        endAdornment={
          <CustomTooltip
            title='Search'
            placement='bottom'
          >
            <IconButton onClick={() => search !== '' && setSearch('')}>
              {search === '' ? <IconSearch/> : <IconClear/>}
            </IconButton>
          </CustomTooltip>
        }
      />
    </Stack>
  )
}

export default DataGridGroupingHeader