import ReactDOMServer from 'react-dom/server'

// LEAFLET
import L from 'leaflet'

// MUIS
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconCircle from '@mui/icons-material/Circle'
import IconNavigation from '@mui/icons-material/Navigation'

const MarkerIcon = (
  classes, 
  inputItem,
  backgroundColor,
) => {  
  return L.divIcon({
    iconSize: [20, 20],
    className: 'custom-icon',
    html: ReactDOMServer.renderToString(
      <>
        <Box className={`${classes.rootMarker} zoom`}>
          {/* ICON */}
          {inputItem?.state?.gps?.speed > 0 ? 
            // ARROW ICON
            <IconNavigation 
              className={classes.markerArrow}
              style={{
                color: backgroundColor,
              }}
            /> : 
            // CIRCLE ICON
            <IconCircle 
              className={classes.markerCircle}
              style={{ fill: backgroundColor }}
            />}
        </Box>

        <Typography
          variant='caption'
          className={classes.text}
          style={{
            backgroundColor: backgroundColor,
            position: 'absolute',
            top: '50%',
            left: '16px',
            transform: 'translateY(-50%)'
          }}
        >
          {inputItem.label}
        </Typography>
      </>
    ),
  })
}

export default MarkerIcon