import { useEffect, useContext, useRef } from 'react'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// LEAFLET
import L from 'leaflet'
import '@geoman-io/leaflet-geoman-free'  
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'  

const GeofenceList = (props) => {
  const { map } = props

  const { 
    hasGeofenceListFinishedLoading,
    selectedGeofenceList, 
  } = useContext(PageTrackingContext)

  const geoJsonGeofenceListRef = useRef()

  const drawGeofenceListToMap = () => {
    if (geoJsonGeofenceListRef.current) geoJsonGeofenceListRef.current.clearLayers()

    // ADD GEOFENCE LIST (GEO JSON DATA) FROM THE SELECTED GEOFENCE LIST
    const featuresList = selectedGeofenceList.map(item => {
      if (item.type === 'polygon') {
        const { bounds, pointList, ...properties } = item

        return {
          type: 'Feature',
          properties: properties,
          geometry: {
            type: 'Polygon',
            coordinates: [ item.pointList.map(point => [ point.lng, point.lat, 0 ]) ],
          },
        }
      }
      else if (item.type === 'circle') {
        const { bounds, center, ...properties } = item

        return {
          type: 'Feature',
          properties: properties,
          geometry: {
            type: 'Point',
            coordinates: [ item.center.lng, item.center.lat, 0 ],
          },
        }
      }
      else return null
    })

    const geoJsonData = {
      type: 'FeatureCollection',
      features: featuresList,
    }

    // SHOW GEOFENCES TO THE MAP
    geoJsonGeofenceListRef.current = L.geoJson(geoJsonData, {
      pointToLayer: (feature, latlng) => {
        if (feature.properties.radius) return new L.Circle(latlng, feature.properties.radius)
        else return new L.Marker(latlng)
      },
      style: (feature) => {
        return {
          fillColor: `#${feature.properties.color}`,
          color: `#${feature.properties.color}`,
          fillOpacity: 0.2,
          opacity: 0.2,
        }
      },
    })
    
    geoJsonGeofenceListRef.current.addTo(map)
    
    const newMapBoundList = geoJsonGeofenceListRef.current.getBounds()
    map.fitBounds(newMapBoundList)
  }

  useEffect(() => {
    if (map && selectedGeofenceList.length > 0 && hasGeofenceListFinishedLoading) {
      drawGeofenceListToMap()
    }
  }, [map, selectedGeofenceList, hasGeofenceListFinishedLoading])

  return null
}

export default GeofenceList