import { useEffect } from 'react'

const MapCamera = (props) => {
  const { locationItems, map, followMarker, settingList } = props

  const checkingIsValidLatLng = (inputLatLngs = []) => {
    let isValid = true
    if (inputLatLngs.length) {
      inputLatLngs.forEach(latLng => {
        if (latLng.length === 2) {
          if(typeof latLng[0] === 'number' && typeof latLng[1] === 'number') {
            isValid = true
          } else {
            isValid = false
          }
        } else {
          isValid = false
        }
      })
    }

    return isValid
  }

  useEffect(() => {
    if(locationItems.length === 1) {
      checkingIsValidLatLng(locationItems) && map.flyTo(locationItems[0], 16, {
        animate: false,
      })
    }
    else if(locationItems.length > 1) {
      checkingIsValidLatLng(locationItems) && map.fitBounds(locationItems)
    }

    followMarker()
  }, [locationItems, settingList])
  
  return null
}

export default MapCamera