// MUI ICONS
import IconBattery0Bar from '@mui/icons-material/Battery0Bar'
import IconBattery1Bar from '@mui/icons-material/Battery1Bar'
import IconBattery2Bar from '@mui/icons-material/Battery2Bar'
import IconBattery3Bar from '@mui/icons-material/Battery3Bar'
import IconBattery4Bar from '@mui/icons-material/Battery4Bar'
import IconBattery5Bar from '@mui/icons-material/Battery5Bar'
import IconBattery6Bar from '@mui/icons-material/Battery6Bar'
import IconBatteryFull from '@mui/icons-material/BatteryFull'
import IconCircle from '@mui/icons-material/Circle'
import IconGpsFixed from '@mui/icons-material/GpsFixed'
import IconList from '@mui/icons-material/List'
import IconMoreVert from '@mui/icons-material/MoreVert'
import IconRadar from '@mui/icons-material/Radar'
import IconSignalCellular0Bar from '@mui/icons-material/SignalCellular0Bar'
import IconSignalCellular1Bar from '@mui/icons-material/SignalCellular1Bar'
import IconSignalCellular2Bar from '@mui/icons-material/SignalCellular2Bar'
import IconSignalCellular3Bar from '@mui/icons-material/SignalCellular3Bar'
import IconSignalCellular4Bar from '@mui/icons-material/SignalCellular4Bar'
import IconSortByAlpha from '@mui/icons-material/SortByAlpha'

export const initialMoreList = [
  {
    text: 'Clusterize object markers',
    isSelected: true,
  },
  {
    text: 'Objects labels',
    isSelected: true,
  },
  {
    text: 'Trace',
    isSelected: true,
  },
  {
    text: 'Animation',
    isSelected: true,
  },
  {
    text: 'Show only selected objects',
    isSelected: false,
  },
  {
    text: 'Hide offline objects',
    isSelected: false,
  },
]

export const initialSettingList = [
  {
    icon: IconList,
    tooltipText: 'Do not group',
    isSelected: false,
  },
  {
    icon: IconSortByAlpha,
    tooltipText: 'By name',
    isSelected: false,
  },
  {
    icon: IconCircle,
    tooltipText: 'By status',
    isSelected: false,
  },
  {
    icon: IconRadar,
    tooltipText: 'By distance',
    isSelected: false,
  },
  {
    icon: IconGpsFixed,
    tooltipText: 'Follow the selected object',
    isSelected: true,
  },
  {
    icon: IconMoreVert,
    tooltipText: 'More',
  },
]

export const dataGridRowHeight = 48

export const batteryIconList = [
  IconBattery0Bar,
  IconBattery1Bar,
  IconBattery2Bar,
  IconBattery3Bar,
  IconBattery4Bar,
  IconBattery5Bar,
  IconBattery6Bar,
  IconBatteryFull,
]

export const signalIconList = [
  IconSignalCellular0Bar,
  IconSignalCellular1Bar,
  IconSignalCellular2Bar,
  IconSignalCellular3Bar,
  IconSignalCellular4Bar,
]