// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  mapContainer: {
    position: 'relative',
    zIndex: 1,
    '&.leaflet-container': {
      width: '100%',
      height: '100%',
    },
  },
  mapContainerCrosshairCursor: {
    '&.leaflet-container': {
      cursor: 'crosshair !important',
    },
  },
  containerPanelObject: {
    position: 'absolute',
    right: 20,
    top: 20,
    width: 'auto',
    zIndex: 1,
  },
  rootMarker: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  markerArrow: {
    height: 24,
    width: 24,
    filter : 'drop-shadow(0px 0px 3px rgba(0,0,0,0.4))',
  },
  markerCircle: {
    height: 20,
    width: 20,
    filter : 'drop-shadow(0px 0px 3px rgba(0,0,0,0.4))',
  },
  text: {
    color: theme.palette.common.white,
    whiteSpace: 'nowrap',
    marginLeft: '8px !important',
    padding: '2px 8px',
    textAlign: 'center',
    opacity: 0.85,
    filter: 'drop-shadow(0px 0px 3px rgba(0,0,0,0.4))',
    fontWeight: 600,
  },
}))

export default useStyles