export const getConnectionStatusObject = (
  inputStatus,
  inputTheme,
) => {
  let output = {}

  if (inputStatus === 'just_registered') {
    output.text = 'Just Registered'
    output.color = inputTheme.palette.action.active
  }
  else if (inputStatus === 'offline') {
    output.text = 'Offline'
    output.color = inputTheme.palette.error.main
  }
  else if (inputStatus === 'active') {
    output.text = 'Online'
    output.color = inputTheme.palette.success.main
  }
  else {
    output.text = 'No Data'
    output.color = inputTheme.palette.action.active
  }

  return output
}