// APIS
import axios from 'apis/axiosBase'

export const postAuthenticateUser = async (
  inputSignal,
  inputBodyParams,
) => {
  try {
    const response = await axios.post(
      '/user/auth',
      inputBodyParams,
      { signal: inputSignal },
    )

    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}