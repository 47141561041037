// ASSETS
import BackgroundBuilding from 'assets/images/backgrounds/authentication-building.png'
import BackgroundGradient from 'assets/images/backgrounds/authentication-gradient.png'

// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
  },
  leftContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.authenticationBackground,
  },
  companyLogo: {
    height: 'fit-content',
    maxWidth: '80%',
    marginTop: 60,
    marginBottom: 16,
  },
  backgroundImage: {
    flex: 1,
    width: '100%',
    backgroundImage: `url(${BackgroundBuilding}), url(${BackgroundGradient})`,
    backgroundSize: 'cover, cover',
    backgroundRepeat: 'no-repeat, no-repeat',
    backgroundPosition: 'center, center',
    backgroundBlendMode: 'multiply',
  },
  rightContent: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: 60,
  },
}))

export default useStyles