// COMPONENTS
import CardIcon from './CardIcon'

// MUIS
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// MUI ICONS
import IconGpsFixed from '@mui/icons-material/GpsFixed'

// STYLES
import useStyles from './panelDetailObjectUseStyles'

const CardDeviceModel = (props) => {
  const { selectedObject } = props

  const classes = useStyles()

  const theme = useTheme()

  const maskDeviceIdText = (inputString) => {
    let output = ''
    for (let i = 0; i < inputString.length; i += 4) {
      output += `${inputString.slice(i, i + 4)} `
    }
    return output
  }

  return (
    <Paper className={classes.contentCard}>
      {/* HEADER */}
      <Stack 
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        {/* TITLE */}
        <Typography variant='caption'>
          Device Model
        </Typography>

        {/* ICON */}
        <CardIcon 
          Icon={IconGpsFixed}
          color={theme.palette.primary.main}
        />
      </Stack>

      {/* INFORMATION LIST */}
      <List disablePadding>
        <ListItem disablePadding>
          {/* TITLE TEXT */}
          <ListItemText 
            primary={
              <Typography className='fontWeight500'>
                {selectedObject?.source?.model ?? 'No Data'}
              </Typography>
            }
          />
        </ListItem>
      </List>

      {/* DEVICE ID TEXT */}
      <Typography 
        variant='caption'
        className={classes.contentCardLastText}
      >
        ID: {selectedObject?.source?.device_id ? maskDeviceIdText(selectedObject.source.device_id) : 'No Data'}
      </Typography>
    </Paper>
  )
}

export default CardDeviceModel