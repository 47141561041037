// RAMDA
import { 
  equals, 
  isEmpty, 
  reject, 
} from 'ramda'

export const isEmailFormatValid = (inputString) => {
  const format = /\S+@\S+\.\S+/
  return format.test(inputString)
}

export const isEqualWith20X = (inputStatus) => {
  const successRegex = /^20[0-9]$/
  return successRegex.test(inputStatus)
}

export const isHashTokenExpired = (inputApiResult) => {
  if (
    inputApiResult.status === 401 && 
    inputApiResult.data.detailMessage === 'User or API key not found or session ended'
  ) return true
  else return false
}

export const isObjectEmpty = (inputObject) => {
  return isEmpty(reject(equals(null), inputObject))
}

export const isPasswordFormatValid = (inputString) => {
  if(inputString.length < 6) return false
  else return true
}