// CONSTANTS
import { dataGridRowHeight } from 'constants/valuesPageTracking'

// MUIS
import { DataGridPro } from '@mui/x-data-grid-pro'
import { styled } from '@mui/material/styles'

const checkboxCellWidth = 42
const cellWidth = 372 - checkboxCellWidth - 4

const CustomDataGridPanel = styled(({ className, componentsProps, ...props }) => (
  <DataGridPro
    checkboxSelection
    rowHeight={dataGridRowHeight}
    headerHeight={dataGridRowHeight}
    hideFooter
    disableColumnMenu
    {...props}
    className={className}
  />
))(({ theme }) => ({
  border: 'none',
  fontSize: 14,
  color: theme.palette.text.primary,

  // HEADER
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-sortIcon': {
    color: theme.palette.primary.main,
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: 0,
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    flex: 1,
    justifyContent: 'center',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    minWidth: `${cellWidth}px !important`,
    padding: 0,
  },
  '& .MuiDataGrid-columnHeaderCheckbox': {
    minWidth: `${checkboxCellWidth}px !important`,
    paddingLeft: 8,
  },

  // CELL
  '& .MuiDataGrid-cell': {
    minWidth: `${cellWidth}px !important`,
    padding: 0,
    border: 'none',
  },
  '& .MuiDataGrid-cellCheckbox': {
    minWidth: `${checkboxCellWidth}px !important`,
    paddingLeft: 0,
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },

  // ROW
  '& .MuiDataGrid-row': {
    cursor: 'pointer',
    padding: '0px 4px',
    width: 372,
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
  },
}))

export default CustomDataGridPanel
