import { useEffect, useRef, useState } from 'react'

// CONTEXT
import { useLeafletContext } from '@react-leaflet/core'

// MUIS
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './mapClusterMenuUseStyles'

/**
 * @props isShowMenu = { mouseEvent: event, clusterData, superclusterRef }
 * @props setIsShowMenu = object or boolean
 */
const MapClusterMenu = (props) => {
  const {
    isShowMenu,
    setIsShowMenu,
    onMenuItemClick,
  } = props

  const classes = useStyles()

  // REFS
  const mapContext = useRef(useLeafletContext())
  const mapSize = useRef()
  const menuWrapRef = useRef(null)

  // STATES
  const [point, setPoint] = useState({
    x: 0,
    y: 0
  })
  const [listData, setListData] = useState([])

  useEffect(() => {
    if(mapContext.current.map && Boolean(isShowMenu)) {
      mapSize.current = mapContext.current.map.getSize()

      // DISABLED SCROLL ZOOM
      mapContext.current.map.scrollWheelZoom.disable()

      // DISABLED PANEL WHEN MAP EVENT ON
      mapContext.current.map.on('click dragstart zoom', () => {
        setIsShowMenu(false)
      })

      mapContext.current.map.on('resize', (event) => {
        mapSize.current = event.newSize
      }, [])

      // CHECK MENU HEIGHT
      const menuPointYisOverFlow = (pointY, menuWrapHeight, mapSize) => {
        if (pointY > (mapSize.y - menuWrapHeight)) return pointY - menuWrapHeight
        else return pointY
      }

      const pointMarker = isShowMenu.mouseEvent.containerPoint
      const menuWrapWidth = menuWrapRef.current ? Number(menuWrapRef.current.offsetWidth) : 0
      const menuWrapHeight = menuWrapRef.current ? Number(menuWrapRef.current.offsetHeight) : 0

      // CHECK IF CLICK POSITION OVERFLOW X AND Y
      if(mapSize.current && pointMarker.x > (mapSize.current.x - menuWrapWidth)) {
        // CHECK MAX POINT X
        const calculationX = pointMarker.x === mapSize.current.x
          ? (pointMarker.x - menuWrapWidth) - 20
          : pointMarker.x - menuWrapWidth

        setPoint({
          y: menuPointYisOverFlow(pointMarker.y, menuWrapHeight, mapSize.current),
          x: calculationX
        })
      } else {
        mapSize.current && setPoint({
          y: menuPointYisOverFlow(pointMarker.y, menuWrapHeight, mapSize.current),
          x: pointMarker.x
        })
      }

      setListData(isShowMenu.superclusterRef.current
        .getLeaves(isShowMenu.clusterData.id, 1000)
        .sort((a, b) => a.markerData.group.color.localeCompare(b.markerData.group.color)))
    } else {
      // ENABLE SCROLL ZOOM
      mapContext.current.map.scrollWheelZoom.enable()
      setListData([])
    }
  }, [isShowMenu])

  // MAP CONTEXT NOT FOUND
  if(!mapContext.current) return null

  return (
    <Box
      ref={menuWrapRef}
      className={classes.root}
      sx={{
        display: Boolean(isShowMenu) ? 'block' : 'none',
        top: `${point.y - 36}px`,
        left: `${point.x + 44}px`,
      }}
    >
      <List 
        disablePadding
        className={`${classes.objectList} zoom`}
      >
        {listData.map((item, index) => (
          <ListItemButton
            key={index}
            className={classes.objectItem}
            sx={{
              borderLeftColor: `#${item.markerData?.group?.color?.replace('#', '')} !important`,
            }}
            onClick={() => onMenuItemClick(item)}
          >
            {/* LABEL */}
            <ListItemText primary={
              <Typography 
                variant='inherit'
                noWrap
              >
                {item.markerData.label}
              </Typography>
            }/>
          </ListItemButton>
        ))}
      </List>
    </Box>
  )
}

export default MapClusterMenu