// COMPONENTS
import CardIcon from './CardIcon'

// CONSTANTS
import { 
  batteryIconList, 
  signalIconList,
} from 'constants/valuesPageTracking'

// DATE AND TIME
import moment from 'moment'

// MUIS
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// MUI ICONS
import IconCircle from '@mui/icons-material/Circle'
import IconQueryStats from '@mui/icons-material/QueryStats'

// STYLES
import useStyles from './panelDetailObjectUseStyles'

// UTILITIES
import { getConnectionStatusObject } from 'utilities/color'

const CardStatus = (props) => {
  const { selectedObject } = props

  const classes = useStyles()

  const theme = useTheme()

  const getConnectionStatus = (inputStatus) => {
    if (inputStatus === 'just_registered') return 'Just Registered'
    else if (inputStatus === 'offline') return 'Offline'
    else if (inputStatus === 'active') return 'Online'
    else return 'No Data'
  }

  const getIcon = (inputLevel, inputList) => {
    if (!inputLevel || inputLevel === 0) return inputList[0]
    else {
      const divider = 100 / inputList.length
      return inputList[Math.ceil(inputLevel / divider) - 1]
    }
  }

  const statusCardList = [
    {
      icon: IconCircle,
      text: getConnectionStatus(selectedObject?.state?.connection_status),
      value: null,
    },
    {
      icon: getIcon(selectedObject?.state?.battery_level, batteryIconList),
      text: 'Battery level',
      value: selectedObject?.state?.battery_level ? `${selectedObject?.state?.battery_level}%` : '-',
    },
    {
      icon: getIcon(selectedObject?.state?.gsm?.signal_level, signalIconList),
      text: selectedObject?.state?.gsm?.network_name ?? 'No Signal Name',
      value: selectedObject?.state?.gsm?.signal_level ? `${selectedObject?.state?.gsm?.signal_level}%` : '-',
    },
  ]

  return (
    <Paper className={classes.contentCard}>
      {/* HEADER */}
      <Stack 
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        {/* TITLE */}
        <Typography variant='caption'>
          Status
        </Typography>

        {/* ICON */}
        <CardIcon 
          Icon={IconQueryStats}
          color={theme.palette.secondary.main}
        />
      </Stack>

      {/* INFORMATION LIST */}
      <List disablePadding>
        {statusCardList.map((item, index) => (
          <ListItem 
            key={index}
            disablePadding
          >
            {/* ICON */}
            <ListItemIcon className={classes.contentCardIcon}>
              <item.icon sx={{ 
                color: index !== 0 
                  ? theme.palette.success.main 
                  : getConnectionStatusObject(selectedObject?.state?.connection_status, theme),
                height: index === 0 ? 12 : 20,
              }}/>
            </ListItemIcon>

            {/* TITLE TEXT */}
            <ListItemText 
              primary={
                <Typography className='fontWeight500'>
                  {item.text}
                </Typography>
              }
            />

            {/* VALUE TEXT */}
            <Typography 
              variant='caption'
              className='colorTextSecondary'
            >
              {item.value}
            </Typography>
          </ListItem>
        ))}
      </List>

      {/* TIME TEXT */}
      <Typography 
        variant='caption'
        className={classes.contentCardLastText}
      >
        {selectedObject?.state?.last_update 
          ? moment(selectedObject.state.last_update).fromNow()
          : 'No Last Update Data'
        }
      </Typography>
    </Paper>
  )
}

export default CardStatus