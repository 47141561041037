import { useState, useEffect, useContext } from 'react'

// COMPONENTS
import CardIcon from './CardIcon'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// DATE AND TIME
import moment from 'moment'

// MUIS
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// MUI ICONS
import IconLocationOn from '@mui/icons-material/LocationOn'

// SERVICES
import { getAddressFromLatitudeAndLongitude } from 'services/base/geocoder'

// STYLES
import useStyles from './panelDetailObjectUseStyles'

// UTILITIES
import { signOutUser } from 'utilities/authentication'
import { isHashTokenExpired } from 'utilities/validation'

const CardLocation = (props) => {
  const { selectedObject } = props

  const { auth, setAuth } = useContext(AllPagesContext)

  const classes = useStyles()

  const theme = useTheme()

  const [ address, setAddress ] = useState('')

  const getLatitudeLongitudeAltitudeTexts = (inputObject) => {
    let output = ''

    if (inputObject?.state?.gps?.location?.lat && inputObject?.state?.gps?.location?.lng) {
      output = `${inputObject.state.gps.location.lat}, ${inputObject.state.gps.location.lng}`
    }
    else output = 'No Latitude and Longitude Data'

    if (inputObject?.state?.gps?.alt) output = `${output}, (Altitude ${inputObject?.state?.gps?.alt} m)`
    else output = `${output} (No Altitude Data)`

    return output
  }

  const getAddress = async (inputIsMounted, inputAbortController) => {
    const resultAddress = await getAddressFromLatitudeAndLongitude(
      inputAbortController.signal,
      { 
        hash: auth.hashToken, 
        lat: selectedObject?.state?.gps?.location?.lat,
        lng: selectedObject?.state?.gps?.location?.lng,
      },
    )

    if (resultAddress.status === 200 && inputIsMounted) setAddress(resultAddress?.data?.value)
    else if (isHashTokenExpired(resultAddress)) signOutUser(setAuth)
    else setAddress('No Address Found')
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    getAddress(isMounted, abortController)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [selectedObject])

  return (
    <Paper className={`${classes.contentCard} ${classes.contentCardLocation}`}>
      {/* HEADER */}
      <Stack 
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        {/* TITLE */}
        <Typography variant='caption'>
          Location
        </Typography>

        {/* ICON */}
        <CardIcon 
          Icon={IconLocationOn}
          color={theme.palette.success.main}
        />
      </Stack>

      {/* INFORMATION LIST */}
      <List disablePadding>
        <ListItem disablePadding>
          {/* TITLE TEXT */}
          <ListItemText 
            primary={
              <Typography className={classes.contentCardLocationText}>
                {address}
              </Typography>
            }
            secondary={
              <Typography className='fontWeight500'>
                {getLatitudeLongitudeAltitudeTexts(selectedObject)}
              </Typography>
            }
          />
        </ListItem>
      </List>

      {/* FOOTER TEXTS */}
      <Stack 
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        className={classes.contentCardLastText}
      >
        {/* TIME TEXT */}
        <Typography variant='caption'>
          {selectedObject?.state?.gps?.updated 
            ? moment(selectedObject.state.gps.updated).fromNow()
            : 'No Last Update Data'
          }
        </Typography>

        {/* SPEED TEXT */}
        <Typography variant='caption'>
          Speed:&nbsp;
          <Box 
            component='span'
            className='colorTextPrimary'
          >
            {selectedObject?.state?.gps?.speed} km/h
          </Box>
        </Typography>
      </Stack>
    </Paper>
  )
}

export default CardLocation