// DATE AND TIME
import moment from 'moment'

// MUIS
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// STREET VIEW
import ReactStreetview from 'react-streetview'

// STYLES
import useStyles from './panelDetailObjectUseStyles'

const CardStreetView = (props) => {
  const { 
    selectedObject, 
    setIsDialogStreetViewOpen,
  } = props

  const classes = useStyles()

  const streetViewPanoramaOptions = {
    addressControl: false,
    disableDefaultUI: true,
    disableDoubleClickZoom: false,
    fullscreenControl: false,
    scrollwheel: false,
    showRoadLabels: false,
    position: selectedObject ? selectedObject?.state?.gps?.location : {},
    pov: { heading: 100, pitch: 0 },
    zoom: 1,
    zoomControl: false,
  }

  const isLocationAvailable = selectedObject?.state?.gps?.location?.lat && selectedObject?.state?.gps?.location?.lng

  return (
    <Paper className={`${classes.contentCard} ${classes.contentCardStreetView}`}>
      {/* TRANSPARENT BOX */}
      <Box 
        className={classes.contentCardStreetViewTransparent}
        sx={{ cursor: isLocationAvailable ? 'pointer' : 'default' }}
        onClick={() => isLocationAvailable && setIsDialogStreetViewOpen(true)}
      />

      {/* HEADER */}
      {isLocationAvailable &&
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        className={classes.contentCardStreetViewHeader}
      >
        {/* STREET VIEW TEXT */}
        <Typography
          variant='caption'
          className='colorWhite'
        >
          Street View
        </Typography>

        {/* LAST UPDATED TEXT */}
        <Typography
          variant='caption'
          className='colorWhite'
        >
          {moment(selectedObject?.state?.gps?.updated).fromNow()}
        </Typography>
      </Stack>}
      
      {/* STREET VIEW */}
      <Stack className={classes.contentCardStreetViewContainer}>
        {isLocationAvailable ?
          <ReactStreetview
            key={selectedObject?.state?.gps?.location?.lat || selectedObject?.state?.gps?.location?.lng}
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            streetViewPanoramaOptions={streetViewPanoramaOptions}
          /> :
          <Stack
            justifyContent='center'
            alignItems='center'
            flex='1'
          >
            <Typography variant='body2'>
              No Street View Available
            </Typography>
          </Stack>}
      </Stack>
    </Paper>
  )
}

export default CardStreetView