import { useContext } from 'react'

// CONTEXTS
import { PageTrackingContext } from 'contexts/PageTrackingContext'

// MUIS
import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'

// STYELS
import useStyles from './panelCreateOrEditGeofenceUseStyles'

const PanelCreateOrEditGeofence = (props) => {
  const { getBottomPosition } = props

  const classes = useStyles()

  const { isPanelCreateOrEditGeofenceShown } = useContext(PageTrackingContext)

  return (
    <Fade in={isPanelCreateOrEditGeofenceShown}>
      <Stack 
        className={classes.root}
        sx={{ bottom: getBottomPosition() }}
      >
        PanelCreateOrEditGeofence
      </Stack>
    </Fade>
  )
}

export default PanelCreateOrEditGeofence