// APIS
import axios from 'apis/axiosEntLacak'

export const postAuthenticateLacakUser = async () => {
  try {
    const response = await axios.post(
      '/user/auth',
      {
        login: process.env.REACT_APP_ENT_LACAK_EMAIL_ADDRESS,
        password: process.env.REACT_APP_ENT_LACAK_PASSWORD,
        dealer_id: process.env.REACT_APP_ENT_LACAK_DEALER_ID,
        locale: 'en',
        hash: null,
      },
    )

    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}