// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    left: 0,
    right: 0,
  },
  togglePanel: {
    marginLeft: 16,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    boxShadow: `0px 4px 24px ${alpha(theme.palette.common.black, 0.12)}`,
    width: 'fit-content',
    padding: '0px 16px',
    height: 40,
    backgroundColor: theme.palette.common.white,
  },
  togglePanelLabel: {
    margin: '0px 20px',
  },
  content: {
    padding: 24,
    height: 220,
    flexBasis: '220px !important',
    backgroundColor: theme.palette.grey[50],
  },
  contentCard: {
    width: 200,
    borderRadius: 12,
    padding: 16,
    boxShadow: `0px 8px 16px ${alpha(theme.palette.grey[500], 0.16)}`,
    display: 'flex',
    flexDirection: 'column',
  },
  contentCardHeaderAvatar: {
    width: 24,
    height: 24,
  },
  contentCardHeaderIcon: {
    width: 16,
    height: 16,
  },
  contentCardIcon: {
    minWidth: 'unset',
    marginRight: 8,
  },
  contentCardLastText: {
    marginTop: 'auto',
    color: theme.palette.text.secondary,
  },
  contentCardStreetView: {
    width: 260,
    padding: 0,
    position: 'relative',
    zIndex: 1,
  },
  contentCardStreetViewTransparent: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'transparent',
    borderRadius: 'inherit',
  },
  contentCardStreetViewHeader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: '8px 12px',
    zIndex: 2,
  },
  contentCardStreetViewContainer: {
    borderRadius: 'inherit',
    height: '100%',
    width: '100%',
    '& div': {
      borderRadius: 'inherit',
    },
  },
  contentCardLocation: {
    width: 320,
  },
  contentCardLocationText: {
    fontWeight: 500,
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3 !important',
  },
  dialogStreetView: {
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    '& .MuiPaper-root': {
      maxWidth: 'unset',
      maxHeight: 'unset',
      margin: 0,
      height: '80vh',
      width: '80vw',
    },
  },
  dialogStreetViewContentContainer: {
    padding: 0,
  },
  dialogStreetViewContent: {
    width: '100%',
    height: '100%',
  },
}))

export default useStyles