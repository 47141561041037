// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    right: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    width: 40,
    height: 40,
    display: 'flex',
    boxShadow: theme.shadows[4],
  },
}))

export default useStyles