// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconCropFree from '@mui/icons-material/CropFree'
import IconRadioButtonChecked from '@mui/icons-material/RadioButtonChecked'

const DataGridCell = (props) => {
  const { row } = props
  
  return (
    <Stack
      direction='row'
      alignItems='center'
      columnGap='16px'
    >
      {/* ICON */}
      {row.type === 'circle' && <IconRadioButtonChecked color='action'/>}
      {row.type === 'polygon' && <IconCropFree color='action'/>}

      {/* TEXT */}
      <Typography variant='inherit'>
        {row.label}
      </Typography>
    </Stack>
  )
}

export default DataGridCell