// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  baseCircle: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  root: {
    width: 64,
    height: 64,
    transform: 'translate(-50%, -50%)',
  },
  blinkingCircle: {
    width: 32,
    height: 32,
    position: 'absolute',
    transform: 'unset',
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    animation: '$enlarge 1.2s linear 0.2s infinite',
  },
  '@keyframes enlarge': {
    '0%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(2)',
    },
  },
  iconContainer: {
    width: 32,
    height: 32,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.common.white}`,
  },
}))

export default useStyles