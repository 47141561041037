// MUIS
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

// STREET VIEW
import ReactStreetview from 'react-streetview'

// STYLES
import useStyles from './panelDetailObjectUseStyles'

const DialogStreetView = (props) => {
  const { 
    selectedObject,
    isDialogStreetViewOpen, setIsDialogStreetViewOpen, 
  } = props

  const classes = useStyles()

  const streetViewPanoramaOptions = {
    addressControl: true,
    fullscreenControl: false,
    position: selectedObject ? selectedObject?.state?.gps?.location : {},
    pov: { heading: 100, pitch: 0 },
    showRoadLabels: true,
    zoom: 1,
    zoomControl: true,
  }
  
  return (
    <Dialog
      open={isDialogStreetViewOpen}
      onClose={() => setIsDialogStreetViewOpen(false)}
      className={classes.dialogStreetView}
    >
      {/* CONTENT */}
      <DialogContent className='padding0'>
        <Box className={classes.dialogStreetViewContent}>
          {(selectedObject?.state?.gps?.location?.lat &&
          selectedObject?.state?.gps?.location?.lng) &&
          <ReactStreetview
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            streetViewPanoramaOptions={streetViewPanoramaOptions}
          />}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DialogStreetView